@import "../../../../public/styles/index.scss";

._line {
  width: 45%;
  height: 0.0625rem;
  background-color: $silver;
}

._circle {
  $size: 0.38rem;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 0.063rem solid $silver;
}

._separator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 2.25rem auto 0 auto;
}

._registerText {
  display: flex;
  justify-content: center;
  margin: 1.375rem auto 0 auto;
  font-family: $space;
  color: $gray;
  font-size: 0.75rem;

  & p:nth-child(2) {
    margin-left: 0.3rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

._stepperParent {
  margin-top: 2.25rem;
  display: flex;
  justify-content: center;
}

._footerContainer {
  margin-top: auto;
  padding-top: 0.5rem;
  padding-bottom: 25px;
}
