@import '../../../../../../../public/styles/index.scss';

@keyframes disableAnimation {
  from {
    background-color: $amour;
  }

  to {
    background-color: $silver;
  }
}

@keyframes enableAnimation {
  from {
    background-color: $silver;
  }

  to {
    background-color: $amour;
  }
}

._header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._btnCustom {
  background-color: $amour  !important;
  border: solid 1px $mandy  !important;
  color: $mandy;
  animation-name: enableAnimation;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}

._iconParent {
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
}

._card {
  background-color: $seashellsolid;
  border-radius: 0.375rem;
  padding: 1.125rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.1875rem;
  height: fit-content;
  width: 100%;
  cursor: pointer;
}

._logo {
  margin-right: 0.9375rem;
}

._rightSide {
  display: flex;
}

._title {
  font-weight: bold;
  font-family: $space;
}

._subTitle {
  font-size: 0.625rem;
}

._leftText {
  font-size: 0.625rem;
  color: $gray;
}

._listParent {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 0.7rem;
}

._bold {
  font-weight: bold;
}

._leftSide {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

._emptyStateParent {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
}

._defaultIcon {
  $size: 2.1875rem;
  width: $size;
  height: $size;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $alabaster;
  margin-right: 0.9375rem;
}

._iconParentEmpty {
  $size: 5rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

._textParent {
  & p {
    font-size: 0.75rem;
    font-family: $space;
    margin-top: 2rem;
  }
}

._main {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: $alabaster;
  padding: 1.875rem;
  border-radius: 0.3125rem;
}

._loaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

@include responsive(md) {
  ._emptyStateParent {
    margin-top: 4rem;
  }

  ._main {
    background-color: transparent;
    padding: 0rem;
  }

  ._card {
    background-color: $alabaster;
  }

  ._logo, ._defaultIcon {
    border: 0.0313rem solid $slateGray;
  }

}
