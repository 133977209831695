@import '../../../public/styles/index.scss';

._inputContainer {
  position: relative;
  width: 100%;
}

._hideCalendar {
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
._inputError {
  @include generalInput($valencia, $valencia);
  border: 0.063rem solid $valencia !important;
  @extend ._hideCalendar;
}
._input {
  @include generalInput($black, $mandy);
  @extend ._hideCalendar;
  &:disabled {
    @include generalInput($silver, $silver);
    border: 0.063rem solid $silver !important;
    &::placeholder {
      color: $silver;
    }
  }
}

._inputSuccess {
  @include generalInput($mantis, $mantis);
  border: 0.063rem solid $mantis !important;
  @extend ._hideCalendar;
}

._textError {
  color: $valencia;
  font-size: 0.65rem;
  margin-top: 0.2rem;
}


@include responsive(md) {
  ._input{
    border: solid  0.063rem $gray;
  }
}
