@import '../../../../../../public/styles/index.scss';

._simpleRowChild {
  padding: 1.25rem 0.625rem;
  box-sizing: border-box;
  border-bottom: 0.0313rem solid $gray;
}

._simpleRowNoLine {
  @extend ._simpleRowChild;
  border-bottom: none;
}

._list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: $space;
  font-size: 0.75rem;

  & li {
    line-height: 1.0625rem;
  }

  & li:first-child {
    font-weight: bold;
  }
}

._iconText {
  display: flex;
}

._iconParent {
  margin-right: 0.25rem;
}

._arrowIconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
