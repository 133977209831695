@import '../../../public/styles/index.scss';

@mixin main{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1308;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

._mainStatic {
  @include main;
  transform: translateY(-100%)
}

._mainIn {
  @include main;
  animation: main-in 0.5s linear forwards;
}

._mainOut{
  @include main;
  animation: main-out 0.5s linear forwards;
}

@keyframes main-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes main-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}


._content{
  padding: 0 1.6875rem 0 2.1875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

._closeIconParent {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}

._pointer {
  cursor: pointer;
}

._text{
  font-family: $space;
  font-size: 1rem;
  color: $amour;
  font-weight: 700;

  & a{
    text-decoration: underline;
    color: $amour;
    cursor: pointer;
    :active{
      text-decoration: none;
      color: $amour;
      a.nostyle:link {
        text-decoration: inherit;
        color: $amour;
    }
    }

  }
}

@include responsive(md) {
  ._notification{
    height: 5rem !important;
  }
  ._content{
    padding: 0 0.875rem 0 0.875rem;
  }
}
