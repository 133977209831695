@import '../../../../../public/styles/index.scss';

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
}

._tableParent {
  display: block;
}

._mobileTableParent {
  display: none;
}

._btnParent {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

._btnContainer {
  width: 12.5rem;
}

._actionsParent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
}

._buttonsParent {
  display: flex;
  align-items: center;
}

._buttonParent {
  width: 12.5rem;
  height: 2.75rem;
}

._sectionParent {
  margin-bottom: 0.5rem;
}

._inputParent {
  width: 14.75rem;
  position: relative;
  margin-right: 1.0625rem;

  & input {
    margin-top: 0rem;
  }
}

._noMargin {
  margin-top: 0rem;
}

._option {
  text-transform: capitalize;
}

._icon {
  position: absolute;
  left: 90%;
  top: 30%;
  transform: translate(-50%, 0);
}

._balanceCardParent {
  width: 19.125rem;
  height: 8.6875rem;
}

._sliderBalanceCardParent {
  width: 100%;
  height: 8.6875rem;
  padding: 0 0.125rem;

}

._responsiveBar {
  display: none;
}

._responsiveBtnParent {
  height: 1.875rem;
  width: 10.1875rem;
}

._tabItem {
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%
}

@include responsive(md) {
  ._actionsParent {
    flex-direction: column;
  }

  ._mainContainer {
    width: 100%;
    flex-direction: column;
  }

  ._tableParent {
    display: none;
  }

  ._mobileTableParent {
    display: block;
  }

  ._balanceCardParent {
    width: 100%;
  }

  ._buttonsParent {
    flex-direction: column;
    width: 100%;
  }

  ._inputParent {
    width: 100%;
    margin: 1.625rem 0;
  }

  ._icon {
    left: 95%;
  }

  ._actionsParent {
    margin-bottom: 0rem;
  }

  ._buttonParent {
    display: none;
  }

  ._slider {
    width: 100%;
    margin-bottom: 1.25rem;
  }

  ._responsiveBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.625rem;
    margin-top: 1rem;
    align-items: center;
  }

  ._barTitle {
    font-family: $space;
    font-size: 1rem;
  }

  ._responsiveActionsParent {
    display: flex;

    & img {
      margin-right: 1.375rem;
    }
  }

}
