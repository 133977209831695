@import '../../../public/styles/index.scss';

._title {
  font-family: $space;
  font-size: 1rem;
  text-align: center;
}

._iconParent {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 1.125rem;
}

._btnsParent {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.8125rem;
}

._btnParent {
  width: 12.125rem;
}

@include responsive(lg) {
  ._btnParent {
    width: 8.25rem;
  }
}
