@import '../../../../../../public/styles/index.scss';


._content{
  display: flex;
  background-color: $mercury;
}

._faqContainer {
  display: flex;
  width: 100%;
}

._categoryText {
  font-family: $space;
  font-size: 1rem;
}
._barTitle {
  font-family: $space;
  font-size: 1rem;
  font-weight: 500;
}

._icon {
  margin-right: 1rem
}

._backgroundAccordion {
  background-color: $mercury;
  border-bottom: .5px solid $placeholderGray;
  margin-top: 1px;
}
._selectedAcordion{
  background-color: $alabaster;
  border-radius: 6px;
  margin-bottom: 1rem;

}

._backgroundAccordionDetail {
  background-color: $mercury;
}

._accordion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

._text {
  font-size: 0.75rem;
  font-family: $space;

  color: $black
}

._link {
  cursor: pointer;
  color: $mandy;

  :active {
    text-decoration: none;
    color: $mandy;

    a.nostyle:link {
      text-decoration: inherit;
      color: $mandy;
    }
  }
}

