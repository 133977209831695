@import '../../../../public/styles/index.scss';

._main {
  width: 100%;
}

._button {
  width: 4.375rem;
  display: flex;
  justify-content: space-between;
  border: none;
  align-items: center;
  height: 2.1875rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0.3125rem;
  z-index: 3;
}

._buttonContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

._text {
  color: $black;
}
