@import '../../../../../public/styles/index.scss';

@keyframes disableAnimation {
  from {
    background-color: transparent;
  }

  to {
    background-color: transparent;
  }
}

@keyframes enableAnimation {
  from {
    background-color: $silver;
  }

  to {
    background-color: transparent;
  }
}


._mainContainer {
  width: 80%;
  height: 90%;
  margin: 3rem auto 0 auto;
  display: flex;
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 5rem;
}

._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._inputContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

._form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

._bottomContainer {
  margin-bottom: 3rem;
}

._error {
  color: red;
  text-align: center;
}

._subtitleL {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  line-height: 0.9375rem;
  color: $black;
}

._otpContainer {
  margin-top: 6rem;
  margin-bottom: 1.5rem;
}

._otp {
  > div {
    > div > input {
      width: 3.8125rem !important;
    }
  }
}

._icon {
  width: 3.4375rem;
  height: 3.4375rem;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  margin: 0.5rem 0 2.1875rem 0;
}

._statusIcon {
  text-align: center;
  margin-top: 1rem;
  > p {
    color: $slateGray;
    margin-top: 1.6563rem;
    font-weight: 700;
  }
}
._tinyTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 1.875rem;
  margin-bottom: 0.8125rem;
  line-height: 15px;
  color: $black;

  & span {
    margin-left: 0.2rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

._buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}


._buttonsContainerTwoFact {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

._buttonLeft {
  margin-top: 6rem;
  margin: 6rem 1rem 1rem 0;
  width: 50%;
}

._buttonRight {
  margin-top: 6rem;
  margin: 6rem 0 1rem 1rem;
  width: 50%;
}

._button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 75%;
}


._footer {
  width: 100%;
  align-self: flex-end;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

._buttonText {
  font-family: $space;
  font-size: 0.8125rem;
  margin-left: 1rem;
}

._googleButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border: 0.063rem solid $black;
  border-radius: 1.25rem;
  cursor: pointer;
  font-family: $space;
  font-weight: 400;
  color: $black;
  animation-name: enableAnimation;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}

._validateText{
  display: flex;
  justify-content: center;

  font-family: $space;
  color: #000000;
  font-size: 0.75rem;
  text-decoration: underline;
  cursor: pointer;

}

@include responsive(lg) {
  ._otp {
    > div {
      justify-content: space-between;
    }
  }
  ._otpContainer {
    margin-top: 0;
  }
  ._fullHeight {
    height: calc(100vh - 5rem);
  }
}

@include responsive(md) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  ._form {
    margin-top: 0;
    margin-bottom: 0;
    height: inherit;
  }

  ._contentInput:first-child {
    margin-top: 0;
  }

  ._inputDualContainer {
    flex-direction: column;
  }

  ._button {
    width: 100%;

    &._button:last-child {
      margin-bottom: 0;
    }
  }
  ._bottomContainer {
    margin-bottom: 2rem;
  }

  ._input {
    margin-top: 0;
  }
  ._otp {
    > div > div > input {
      width: 100% !important;
    }
  }
  ._inputContainer{
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

