@import '../../../../../public/styles/index.scss';

._main {
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
}

._actionsRow {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
  margin-bottom: 1.5625rem;
  align-items: center;
  width: 100%;
}

._cardsParent {
  display: flex;
  align-items: center;
  max-width: 30%
}

._accountCardsParent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

._title {
  font-size: 1.25rem;
}
._subtitle {
  font-family: $space;
  font-weight: 1rem;
  font-weight: bold;
}

._cardParent {
  width: 100%;
  height: 6.875rem;

  &:not(:last-child) {
    margin-right: 1.0625rem;
  }
}

._stepperParent {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 28px;
}

._iconBackParent {
  margin-right: 0.625rem;
  width: 22px;
  cursor: pointer;
  transition: all .6s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: $alabaster;
    transition: all .6s;
  }
}

._iconNextParent {
  cursor: pointer;
  transition: all .6s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: $alabaster;
    transition: all .6s;
  }
}

._branchName{
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  color: $pureBlack;
}

._cardChild {
  display: flex;
  align-items: center;

  & p {
    margin-left: 0.5625rem;
  }
}

._cardContainer {
  margin-top: 1rem;
}

._button {
  width: 48%;
  color: $amour;
  display: flex;
  align-items: center;
  justify-content: center;
}

._buttonsParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

._pointer{
  cursor: pointer;
}

._tableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.9375rem;
  margin-bottom: 1.3125rem;
  & > p {
    font-size: 1rem;
    font-family: $space;
    font-weight: bold;
  }
}

._card {
  background-color: $alabaster;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;

  & p {
    font-size: 12px;
    font-family: $space;
  }

  &:last-child {
    margin-left: 1.875rem
  }
}

._smallCardsParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._smallSquare {
  background-color: $alabaster;
  padding: 14px 10px;
  border-radius: 0.375rem;
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

._arrowIcon {
  transform: rotate(90deg);
  width: 1.5rem;
  height: 1.5rem;
  color: $white;
  margin-right: 0.75rem;
}

//MODAL

._modalCard {
  background-color: $seashellsolid;
  padding: 1.25rem;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.4375rem;
  }
}

._modalTitle {
  margin: 1.25rem 0;
  font-size: 1rem;
}

._leftSide {
  display: flex;
  align-items: center;

  & p {
    margin-left: 0.5rem;
    font-size: $space;
    font-size: 1rem;
  }
}

._rightSide {
  display: flex;
  align-items: center;

  & p {
    font-size: 0.75rem;
    margin-right: 0.5rem
  }
}

._iconText {
  display: flex;
}

._miniCardsParent {
  display: flex;
  justify-content: space-between;

  & div:last-child {
    margin-left: 1.0625rem;
  }
}

._iconMiniCardParent {
  display: flex;
  justify-content: center;
  align-items: center;
}

._miniCard {
  padding: 0.75rem;
  background-color: $alabaster;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.875rem;
  height: 2.5rem;
  width: 2.5rem;
}

._rowChild {
  padding: 0.9375rem 0.625rem;
  box-sizing: border-box;
  border-bottom: 0.0313rem solid $gray;
}

._branchOfficesParent {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}

._skeleton {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  width: 100%;
}

._skRoot {
  transform: scale(1);
}
