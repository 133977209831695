._ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 90%;
}

._mobileRow {
  line-height: 0.9569rem;
  font-size: 0.75rem;
  > p {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

._bold {
  font-weight: bold;
}
._fontSize {
  font-size: 0.75rem;
}



