@import "../../../../../../../public/styles/index.scss";

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
}
._arrowBackContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 9999;
  top: -2.75rem;
  svg {
    margin-right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

._icon {
  position: absolute;
  left: 90%;
  top: 30%;
  transform: translate(-50%, 0);
}

._ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 90%;
}

._mobileRow {
  line-height: 0.9569rem;
  font-size: 0.75rem;
}

@include responsive(lg) {
  ._mainContainer {
    background-color: $athensGray;
    height: 100%;
    padding: 0 0.875rem 1rem 0.875rem;
  }
  ._inputParent {
    width: 100%;
  }
  ._arrowBackContainer {
    display: none;
  }

}
