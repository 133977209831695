@import "../../../../public/styles/index.scss";

._counterPartsContainer {
  margin-top: 1.125rem;
  padding-right: 0.3125rem;
  overflow-y: auto;
  @include scrollbar;
}

._client {
  background-color: $seashellsolid;
  border-radius: 0.375rem;
}

._paginationParent {
  flex: 1;
  align-items: flex-end;
  display: flex;
  width: 100%;
  margin-top: 1rem;
}

._iconParent {
  $size: 4rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

._emptyStateParent {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 0.625rem;
}

._loaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
}

._textParent {
  & p {
    font-size: 0.75rem;
    font-family: $space;
    margin-top: 0.8rem;
  }
}

@include responsive(lg) {
  ._counterPartsContainer {
    padding-right: 0;
  }
}
