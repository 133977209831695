@import "../../../public/styles/index.scss";

._radioMain {
  border: solid   0.031rem$gray;
  border-radius: 0.375rem;
  // max-height: 15rem;
  min-height: 3rem;
  position: relative;
  padding-bottom: 2rem;
}

._iconNone {
  opacity: 0.5;
  cursor: unset
}

._radioContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  // padding: 0 0.5rem;
}
._loaderContainer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._title {
  color: black;
  font-weight: 700;
  font-size: 13px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

._subtitle {
  color: black;
  font-weight: 400;
  font-size: 14px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}


._emailRadio {
  color: $black;
  margin-left: 1rem;
  font-size: 13px;
}

._chooseAll {
  color: $black;
  font-size: 13px;
}

._paginate {
  font-size: 0.75rem;
  margin-left: 0.3rem;
}

._paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.7rem;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
}

._radioContent{
  display: flex;
  width: 100%;
}

._textRadioContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-right: 0.9375rem;
}

._icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

._empty {
  font-family: $space;
  margin: 1rem 1rem 0.7rem 1rem;
  font-size: 0.75rem;
}

._titleContainer{
  margin-top: 1rem;
  margin-left: 0.9375rem;
}

@include responsive(lg) {
  ._textRadioContent {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;

  }

  ._emailRadio {
    margin: 0;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ._chooseAll {
    margin-left: 0.5rem;
  }

  ._radioContainer {
    margin: 0.5rem 0
  }
  ._radioMain{
    border: solid 0.063rem $gray;
  }

  ._radioContent{
    align-items: center;
    width: 100%;
  }

}
