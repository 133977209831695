@import "../../../../../../../public/styles/index.scss";


._bankIcon {
  background-color: $seashellsolid;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}

._defaultIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $seashellsolid;
  margin-right: 0.625rem;

}

._arrowIcon {

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $silver;
  margin-right: 0.625rem;

}

._addIcon {
  margin-right: 0.625rem;
}

._manageTitle {
  font-size: 1rem;
  color: $mandy;
  cursor: pointer
}



._titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1rem 0
}

._title {
  font-size: 1rem;
  color: $black;
}


._accountSubtitle {
  font-family: $space;
  font-size: 0.75rem;

}

._bottomContainer {
  padding-bottom: 2rem;
  background-color: $white;
}

._accountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $alabaster;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

}

._row {
  display: flex;
}


._miniTitle {
  font-size: 0.625rem;
  font-weight: 700;
  color: $placeholderGray;
}

._miniSubTitle {
  font-size: 0.625rem;
  font-weight: 400;
  color: $placeholderGray;
}

._accountTitle {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;

}

._loaderContainer {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



@include responsive(lg) {
  ._btnParent {
    width: 8.25rem;
  }

  ._accountTitle {
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ._accountSubtitle {
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
