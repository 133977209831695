@import '../../../../public/styles/index.scss';

@mixin cardCommon {
  background-color: $alabaster;
  padding: 1.125rem;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

._cardParent {
  @include cardCommon;
}

._cardParentDisabled {
  @include cardCommon;
  opacity: 0.5;
}

._title {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;
  line-height: 115%;
}

._subtitle {
  font-family: $space;
  font-size: 0.75rem;
  line-height: 115%;
}

._amount {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;
  text-align: right;
}

._header {
  display: flex;
  align-items: center;

  & img {
    margin-right: 0.625rem;
  }
}
