@import "../../../public/styles/index.scss";
._input {
  @include generalInput($black, $mandy);

  &:focus {
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    width: 100%;
  }

  &:disabled {
    @include generalInput($silver, $silver);
    border: 0.063rem solid $silver !important;
    &::placeholder {
      color: $silver;
    }
  }
}

._inputError {
  @include generalInput($valencia, $valencia);
  border: 0.063rem solid $valencia !important;
  &:disabled {
    @include generalInput($silver, $silver);
    border: 0.063rem solid $silver !important;
    color: $silver;
    &::placeholder {
      color: $silver;
    }
  }
}
._inputSuccess {
  @include generalInput($mantis, $mantis);
  border: 0.063rem solid $mantis !important;
  &:disabled {
    @include generalInput($silver, $silver);
    border: 0.063rem solid $silver !important;
    color: $silver;
    &::placeholder {
      color: $silver;
    }
  }
}

._textError {
  color: $valencia;
  font-size: 0.65rem;
  margin-top: 0.2rem;
}

._inputContainer {
  position: relative;
}

._iconNoPointer {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate(0, -50%);
  background-color: $white;
}

._iconPointer {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  transform: translate(0, -50%);
  background-color: $white;
}

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._rightIconPadding {
  padding-right: 2rem;
}

@include responsive(md) {
  ._input {
    border: solid 0.063rem $gray;
    &:disabled {
      border: solid 0.063rem $silver;
    }
  }
}
