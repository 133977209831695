@import '../../../../../public/styles/index.scss';

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
._headerContainer {
  width: 40%;
}

._barTitleContainer{
display: flex;
}
._barTitle {
  font-family: $space;
  font-size: 1rem;
  font-weight: 500;
}

._leftContainer {
  width: 40%;

}

._rightContainer {
  width: 60%;
  margin-left: 2rem;
  margin-top: 3rem;
  max-height:80vh;
  overflow: none;
  // overflow-y: scroll;
  // @include scrollbar;
}

._content {
  display: flex;

}

._category {
  background-color: none;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  cursor: pointer;
  width: 100%;
  position: relative;
  min-height: 48px;
}

._selectedCategory{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  cursor: pointer;
  width: 100%;
  position: relative;
  min-height: 48px;
  background-color: $alabaster;
  border-radius: 6px;
}

._categoryContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

._faqContainer {
  display: flex;
  width: 100%;
}

._categoryText {
  font-family: $space;
  font-size: 1rem;
  font-weight: 400;
}

._icon {
  margin-right: 1rem
}

._iconPointer {
  margin-right: 1rem;
  cursor: pointer;
}

._backgroundAccordion {
  background-color: $mercury;
  border-bottom: .5px solid $placeholderGray;
  margin-top: 1px;
}

._backgroundAccordionDetail {
  background-color: $mercury;

}

._accordion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

._text {
  font-size: 0.75rem;
  font-family: $space;
  margin: 1rem 0;
  color: $black
}

._link {
  cursor: pointer;
  color: $mandy;

  :active {
    text-decoration: none;
    color: $mandy;

    a.nostyle:link {
      text-decoration: inherit;
      color: $mandy;
    }
  }
}

@include responsive(md) {
  ._mainContainer{
    padding: 0 0.688rem 1rem 0.688rem
  }
  ._headerContainer{
    width: 100%;
    margin-top: 1rem;
  }
}
