@import '../../../public/styles/index.scss';

._container {
  display: flex;
  align-items: center;
  position: relative;
  width: 0.8125rem;
  height: 0.8125rem;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

._container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

._checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  box-sizing: border-box;
  border: 0.0625rem solid $mandy;
  border-radius: 0.125rem;
}

._container:hover input ~ ._checkmark {
  opacity: 0.6;
  background-color: $mandy;
}

._container input:checked ~ ._checkmark {
  background-color: $mandy;
}

._checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

._container input:checked ~ ._checkmark:after {
  display: block;
}

._container ._checkmark:after {
  left: 0.176rem;
  top: 0;
  width: 0.19rem;
  height: 0.5rem;
  border: solid $white;
  border-width: 0 0.125rem 0.125rem 0;
  transform: rotate(45deg);
}

._label {
  font-family: $space;
  font-size: 0.75rem;
  height: 100%;
  color: $black;
  margin-left: 0.4375rem;
}

._mainContainer {
  display: flex;
  align-items: center;
}
