@import "../../../../public/styles/index.scss";

._main {
  display: flex;
  height: 100vh;
}

._leftSide {
  width: 50%;
  padding: 2rem;
  box-sizing: border-box;
}

._header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

._rightSide {
  width: 50%;
  position: fixed;
  top:0;
  right: 0;
  height: 100%;
}

._banner {
  // background-image: url("../../../../public/resources/half-background.svg");
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
._text {
  color: $white;
  font-family: $space;
  font-weight: 600;
  font-size: 3rem;
  width: 50%;
  text-align: center;
  margin-top: 3rem;
}

@include responsive(xxxl) {
  ._text{
    width: 60%;
  }
}

@include responsive(xl) {
  ._text{
    width: 80%;
  }
}

@include responsive(lg) {
  ._rightSide {
    display: none;
  }

  ._leftSide {
    width: 100%;
    padding: 0.9375rem;
  }

  ._main{
    height: 100%;
  }

}
