@import '../../../public/styles/index.scss';

._parent {
  position: absolute;
  z-index: 999;
}
._main {
  background-color: $alto;
  padding: 0.5625rem;
  box-sizing: border-box;
  position: relative;

  & p {
    font-family: $space;
    font-size: 0.75rem;
  }
}

._square {
  $size: 0.75rem;
  width: $size;
  height: $size;
  position: absolute;
  left: 5%;
  bottom: -0.37rem;
  background-color: $alto;
  transform: rotate(45deg);
}
