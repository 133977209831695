@import '../../../../public/styles/index.scss';

._main {
  width: 36.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5625rem;
}

._title {
  font-size: 1.5rem;
  font-family: $space;
  text-align: center;
}

._text {
  font-size: 0.875rem;
  font-family: $space;
  margin-top: 1.25rem;
  width: 70%;
  text-align: center;
  &:last-child {
    margin-bottom: 7.5rem;
  }
}

._codeParent {
  margin: 3.125rem 0 1.875rem 0;
  display: flex;
  flex-wrap: wrap;
}

._codeSquare {
  $size: 2.9375rem;
  width: $size;
  height: $size;
  border: solid 0.0625rem $mandy;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1.25rem;
  }

  & p {
    font-size: 2.5rem;
    color: $mandy;
    font-family: $space;
  }
}

@include responsive(lg) {
  ._main {
    width: 100%;
    height: 60vh;
  }

  ._codeSquare {
    $size: 1.875rem;
    width: $size;
    height: $size;
    &:not(:last-child) {
      margin-right: 0.875rem;
    }

    & p {
      font-size: 24px;
      color: $mandy;
      font-family: $space;
    }
  }

  ._text {
    font-size: 0.875rem;
    font-family: $space;
    margin-top: 1.25rem;
    text-align: center;
    width: 100%;
    &:last-child {
      margin-bottom: 6.5rem;
    }
  }
}
