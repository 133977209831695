@import '../../../../public/styles/index.scss';

._simpleRowChild {
  height: 3.875rem;
  // padding: 0 0.625rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.625rem;
}

._title {
  font-weight: 700;
}

._ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

._emptyRow {
  @extend ._simpleRowChild;
  border-bottom: none;
  background-color: $seashellsolid;
}

._textParent {
  & p {
    font-size: 0.75rem;
    font-family: $space;
    margin-top: 1rem;
  }
}

._parent {
  width: 100%;
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
}

._iconParent {
  $size: 5rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

._emptyStateParent {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
}

._list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: $space;
  font-size: 0.75rem;

  & li:first-child {
    font-weight: bold;
  }
}

._listItem {
  line-height: 1.0625rem;
  margin-left: 10px;
}

._iconText {
  display: flex;
}

._iconParent {
  margin-right: 0.25rem;
  margin-top: 1rem;
}

._arrowIconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-right: 10px;
}

@include responsive(md) {
  ._emptyStateParent {
    margin: 5rem 0rem;
  }

  ._iconParent {
    background-color: $alabaster;
  }

  ._simpleRowChild,
  ._emptyRow {
    background-color: $alabaster;
  }

  ._simpleRowChild {
    height: auto;
    padding: 1.1rem 0.625rem;
    border-bottom: 0.5px solid $gray;

    &:nth-last-child(1) {
      border-radius: 0.625rem 0.625rem 0rem 0rem;
    }
  }

  ._emptyRow {
    height: 3.875rem;
    padding: 0 0.625rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 0.625rem !important;
    border-bottom: none;
    background-color: $seashellsolid;
  }

  ._parent {
    margin: 0;
  }
}
