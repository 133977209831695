@import "../../../../public/styles/index.scss";


._title {
  font-family: $space;
  font-size: 1rem;
  text-align: center;

}

._successTitle{
  font-family: $space;
  font-size: 1rem;
  text-align: center;

  width: 60%;
  margin: 0 auto 2rem auto;
}

._button {
  width: 25%;
  margin-left: auto;
  margin-top: 2.5rem;
}
._2faTitle{
  font-family: $space;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 1rem;
}

._otp {

  > div {

    > div > input {
      width: 3.8125rem !important;
      margin-bottom: 2rem;
    }
  }
}

@include responsive(lg) {

  ._button {
    width: 100%;
    align-self: flex-end;
    margin-top: 4rem;
  }
  ._otp {
    > div > div > input {
      width: 100% !important;
      border:0.063rem solid $gray;
      border-radius: 0.875rem;
      margin-bottom: 0
    }
  }
}
