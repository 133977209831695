@import '../../../../../public/styles/index.scss';

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._scrollBar {
  & > *:first-child {
    @include scrollbar;
  }
}

._copyright {
  font-size: 0.875rem;
  padding: 0.8125rem 1.6875rem 1.6875rem 2.1875rem;
}

._imageContainer {
  padding: 1.6875rem 1.6875rem 0 2.1875rem;
}

._logo {
  cursor: pointer;
}
