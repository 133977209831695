@import "../../../../public/styles/index.scss";

._main {
  width: 100%;
  box-sizing: border-box;
  background-color: $white;
}

@include responsive(lg) {
  ._main {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    //  height: calc(100vh - 3rem - 2rem);
  }
}
