@mixin responsive($size) {
  @if $size == xxxl {
    @media (max-width: 1600px) {
      @content;
    }
  }
  @if $size == xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }

  @if $size == xl {
    @media (max-width: 1200px) {
      @content;
    }
  }

  @if $size == lg {
    @media (max-width: 992px) {
      @content;
    }
  }
  @if $size == md {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $size == sm {
    @media (max-width: 576px) {
      @content;
    }
  }

  @if $size == xs {
    @media (max-width: 370px) {
      @content;
    }
  }
}

@mixin generalInput($borderColor, $borderFocusColor, $marginTop: true) {
  border: solid 0.031rem $borderColor;
  border-radius: 1.25rem;
  position: relative;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background-color: transparent;
  font-family: $space;
  box-sizing: border-box;
  color: $black;
  &::placeholder {
    color: $placeholderGray;
  }
  &:focus {
    border: solid 0.031rem $borderFocusColor;
  }
  @include responsive(md) {
    &:focus {
      border: solid 0.063rem $borderFocusColor;
    }
  }
}

@mixin card($padding) {
  background-color: $alabaster;
  border-radius: 0.3125rem;
  padding: $padding;
  width: 100%;
}

@mixin generalSelect($borderColor, $borderFocusColor) {
  @include generalInput($borderColor, $borderFocusColor);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(../resources/select-down-arrow.svg);
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 1.5rem 1.5rem, 100%;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.1875rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $alto;
    border-radius: 2.5px;
  }
  @include responsive(lg) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}


