@import "../../../../../../../public/styles/index.scss";

._container {
  display: grid;
  margin-top: 4.25rem;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  grid-auto-flow: dense;
  align-items: center;
  > * {
    cursor: pointer;
  }
  @include responsive(md) {
    grid-template-rows: min-content min-content;
    place-content: center;
    padding: 0 1rem;
    grid-template-columns: auto auto;
    margin-top: 2.125rem;
    grid-gap: 0.5rem 1.125rem;
    & > img:first-child {
      grid-column: 1;
      grid-row: 2;
    }
    & > :nth-child(2) {
      grid-row: 1;
      grid-column: span 2;
      place-self: center;
    }
    & > img:nth-child(3) {
      grid-row: 2;
      grid-column: 2;
    }
  }
}
