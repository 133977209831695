@import "../../../../../public/styles/index.scss";

@mixin optionContainer {
  background-color: $alabaster;
  border-radius: 0.313rem;
  min-height: 4.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.9375rem;
}

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
  position: relative;
}

._paymentContainer {
  display: flex;
  justify-content: space-between;
}
._iconPointer {
  margin-right: 1rem;
  cursor: pointer;
}



._headerContainer {
  width: 40%;
  margin-top: 2rem;
}

._barTitleContainer{
  display: flex;
  }
  ._barTitle {
    font-family: $space;
    font-size: 1rem;
    font-weight: 500;
  }

._topContainer {

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  p {
    font-size: 1rem;
    font-weight: 700;
    font-family: $space;
  }
}

._titleContainer {
  display: flex;
}

._leftContent {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
}

._bottomLeftContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

._optionContainerSelected {

  @include optionContainer;
}
._iconContainer {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

._optionsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._textContent {
  display: flex;
  flex-direction: column;
}


._title {
  font-size: 1rem;
  font-weight: 700;
  font-family: $space;
  color: $black;
  line-height: 1.2763rem;
}

._subtitle {
  font-size: 1rem;
  font-weight: 400;
  font-family: $space;
  color: $silver;
  line-height: 0.9569rem;
}

._optionContainerTiny{
  background-color: $alabaster;
  border-radius: 0.313rem;
  min-height: 3rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.9375rem;
  width: 50%;
}

@include responsive(lg) {
  ._mainContainer {
    height: 100%;
    padding: 2rem 0.875rem 1rem 0.875rem;
  }

  ._headerContainer{
    margin-top: 1rem;
  }

  ._paymentContainer {
    width: 100%;
  }

  ._leftContent {
    width: 100%;
  }
  ._topContainer {

    width: 100%;
  }
  ._optionContainerTiny{
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

}


@include responsive(md) {

  ._optionContainerSelected{
    padding: 1.30rem;
    box-sizing: border-box;
  }
}
