@import "../../../../../../../public/styles/index.scss";

._container {
  margin-top: 3.0625rem;
  justify-content: center;
}

._2FA {
  margin-top: 5.5625rem;
  font-weight: 700;
}

._suggestion {
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 1.1875rem;
}

._passwordInput {
  & div {
    background-color: transparent;
  }
}
