@import "../../../../../../../public/styles/index.scss";

._container {
  display: flex;
  margin-top: 3.0625rem;
  justify-content: center;
}

._secret {
  text-align: center;
  margin-top: 2.375rem;
}

._suggestion {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 2rem;
}
