@import '../../../../../public/styles/index.scss';



._imageContainer{
  background: $seashellsolid;
  border-radius: 0.688rem;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
}

._imageCard{
  $size: 3.938rem;
  width: $size;
  height: $size;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0.4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}

._cardTextContainer{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
 align-items: flex-start;
 margin-left: 1.5rem;
}
._cardTitle{
  color: $black;
  font-size: 1rem;
  font-weight: 800;

}

._cardSubtitle{
  color: $black;
  font-size: 0.75rem;
  text-decoration: none;
}



._titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

._title {
  font-size: 1rem;
  color: $black;
  font-weight: 700;
  margin-top: 2rem;
}

._pinkTitle {
  font-size: 1rem;
  color: $mandy;
  margin-top: 2rem;
  text-decoration: underline;
  cursor: pointer;
}

._main{
  width: 100%;

  background-color: $white;

}

._mainContainer{
width: 95%;
margin: 0 auto;

}


