@import '../../../../../public/styles/index.scss';

._mainContainer {
  padding: 0 1.25rem;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: $mandy;
}

._content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._arrowContainer {
  align-self: flex-start;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: calc(100% - 1rem);
  left: 0;
  right: 0;
  margin: 0 1rem;

}
._mt1{
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

._card {
  background-color: $alabaster;
  border-radius: 1.125rem;
  width: 43.75rem;
}

._title {
  font-family: $space;
  font-size: 3.625rem;
  color: $black;
  margin-top: 1.875rem;
}

._cardContent {
  margin: 3.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._subtitle {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1.25rem;
  margin-bottom: 2.1875rem;
}

._button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 50%;
  align-self: center;
}

@include responsive(lg) {
  ._button {
    width: 50%;
  }
}

@include responsive(md) {
  ._button {
    width: 97%;
  }

  ._mainContainer {
    height: 100vh;
  }

  ._icon {
    width: 3.125rem;
  }

  ._title {
    font-size: 1.5rem;
  }

  ._subtitle {
    font-size: 0.75rem;
  }

  ._cardContent {
    margin: 2.5rem 1.3125rem 0.625rem 1.3125rem;
  }


}
