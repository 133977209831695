@import '../../../../../../public/styles/index.scss';

._main {
  background-color: $seashellsolid;
}

._comebackParent {
  display: flex;
  align-items: center;
  margin-top: 3rem;

  & p {
    font-family: $space;
    margin-left: 0.25rem;
    font-size: 1rem;
    font-weight: bold;
  }
}

._typeWithWallet {
  display: flex;

  & p:first-child {
    font-weight: bold;
    margin-right: 0.5625rem;
  }
}

._card {
  background-color: $white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 1rem;
  margin-top: 1.5rem;
}

._boxTitle {
  font-family: $space;
  font-size: 1rem;
}

._boxMobileTitle {
  font-family: $space;
  font-size: 1rem;
}

._grayRow {
	padding: 1.125rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

  & p {
    font-size: 0.75rem;
    font-family: $space;
  }

	&:not(:last-child) {
		border-bottom: solid 0.063rem $gray;
	}
}

._listArrow {
	display: flex;
	align-items: center;
}

._arrowParent {
	margin-left: 0.3125rem;
}

._list {
	list-style: none;
	padding: 0;
	margin: 0;

  & li {
    font-family: $space;
    font-size: 0.75rem;
  }
}

._boldText {
  font-weight: bold;
}







