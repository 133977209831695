@import '../../../../../../public/styles/index.scss';

._main {
  margin: auto;
  width: 80%;
  display: flex;
  align-items: flex-start;
  margin-top: 2.7rem;
}

._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  margin: 0.5rem 0 2.1875rem 0;
}

._row {
  display: flex;
  justify-content: space-between;
  margin-top: 1.125rem;
}

._halfInput {
  width: 48%;
}
._error {
  color: red;
  margin-top: 1rem;
  text-align: center;
}
._inputParent {
  margin-top: 1.125rem;
}

._formTitle {
  margin-top: 1.875rem;
  font-family: $space;
  font-weight: 500;
  font-size: 0.875rem;
}

._btnParent {
  margin-top: 2.8125rem;
}

._stepperParent {
  margin-top: 2.25rem;
  display: flex;
  justify-content: center;
}

._footerParent {
  margin-top: 58px;
}

._arrowParent {
  display: block;
}

._switchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._2fa {
  font-size: 0.75rem;
}
._recommended {
  font-size: 0.625rem;
  color: $placeholderGray;
}
@include responsive(md) {
  ._main {
    width: 100%;
    flex-direction: column;
  }

  ._arrowParent {
    margin-bottom: 1rem;
  }

  ._row {
    flex-direction: column;
  }

  ._halfInput {
    width: 100%;

    &:nth-child(2) {
      margin-top: 1.125rem;
    }
  }
}
