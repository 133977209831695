@import '../../../../public/styles/index.scss';

._card {
  position: relative;
  height: 10.5625rem;
  width: 100%;
  background-color: $mandy;
  border-radius: 0.5rem;
  z-index: 0;
}

._innerBackground {
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  background-color: $alabaster;
  border-radius: 0.5rem 5.53125rem 0.5rem 0.5rem;
  z-index: 1;
  width: 17.4375rem;
}

._outerBackground {
  padding: 0.9375rem;
  border-radius: 0.6875rem;
  background-color: $seashellsolid;
}

._noBackground {
  background-color: transparent;
  padding: 0;
}

._balance {
  position: absolute;
  top: 0.875rem;
  left: 1.0625rem;
  z-index: 2;
  color: $mandy;
  font-weight: 700;
  max-width: calc(90% - 1.875rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._logo {
  position: absolute;
  width: 2.625rem;
  height: 2.625rem;
  z-index: 2;
  right: 0;
  top: 0;
}

._amountContainer {
  position: absolute;
  z-index: 2;
  bottom: 0.75rem;
  left: 1.0625rem;

  &>* {
    display: inline-block;
    vertical-align: middle;
  }
}

._amount {
  font-size: 2rem;
  font-weight: 700;
  color: $mandy;
}

._hand {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  border-radius: 0 0 0.5rem 0;
}
