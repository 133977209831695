@import '../../../public/styles/index.scss';

@mixin fileStyle {
  border: solid 0.063rem $gray;
  border-radius: 1.25rem;
  width: 75%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background-color: transparent;
  font-family: $space;
  box-sizing: border-box;
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: 1rem;
}

._fileContainer {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

._main{
  display: flex;
  justify-content: space-between;
}

._file {
  @include fileStyle();
  border: solid 0.063rem $gray;
}

._fileSelected {
  @include fileStyle();
  border: solid 0.063rem $gray;
}


._btn {
  width: 100%;
  height: 2.5rem;
  background-color: $mandy;
  border: none;
  color: $white;
  border-radius: 1.25rem;
  cursor: pointer;
  font-family: $space;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

._iconContainer {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

._fileName {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1.25rem;
  color: $black;
  font-family: $space;

}

._fileNameStatic {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1.25rem;
  color: $placeholderGray;
  font-family: $space;
  font-size:0.813rem;
}


@include responsive(md) {
  ._fileContainer {
    width: 19.44%;
  }
}
