@import '../../../public/styles/index.scss';

@mixin drop {
  background-color: $alabaster;
  position: absolute;
  border-radius: 0.313rem;
  top: 30px;
  right: 10px;
  z-index: 1300;
  transition: all .6s;
  height: 0px;
  width: 6.8rem;
}

._dropStatic {
  @include drop;
  height: 7.625rem;
}

._dropHidden {
  @include drop;
  height: 0rem;
  overflow: hidden;
}

._arrowContainer {
  margin-right: 1rem;
}

@mixin dropTitle {
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  text-align: left;
  transition: all .6s;
  height: 0px;
  &:hover {
    background-color: $alto;
    transition: all .6s;
  }
}

@mixin _dropText{
  font-family: $space;
  font-weight: 400;
  font-size: 0.75rem;
  transition: all .6s;
}

._dropTextStatic{
  @include _dropText;
  height: 100%

}

._dropTextHidden{
  @include _dropText;
  height: 0px
}

._dropTitleStatic {
  @include dropTitle;
  height: 33.3%;
}

._dropTitleHidden {
  @include dropTitle;
  height: 0px;
  overflow: hidden;
}


@include responsive(md) {
  ._baseBtn{
    justify-content: space-between;
    align-items: center;
  }
}
