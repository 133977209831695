@import '../../../../../public/styles/index.scss';

._mainContainer {
  width: 80%;
  height: 90%;
  margin: 3rem auto 0 auto;
  display: flex;
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 5rem;
}

._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._inputContainer {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

._form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

._bottomContainer {
  margin-bottom: 5rem;
}



._error {
  color: red;
  text-align: center;
}

._tinyTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 1.875rem;
  margin-bottom: 0.8125rem;
  line-height: 15px;
  color: $black;

  & span {
    margin-left: 0.2rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

._buttonsContainer {
  display: flex;

  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

._button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 75%;
}

._buttonLeft {
  margin-top: 6rem;
  margin: 6rem 1rem 1rem 0;
  width: 50%;
}

._buttonRight {
  margin-top: 6rem;
  margin: 6rem 0 1rem 1rem;
  width: 50%;
}

._footer {
  width: 100%;
  align-self: flex-end;
}

._buttonText {
  font-family: $space;
  font-size: 0.8125rem;
  margin-left: 1rem;
}


._otp {
  > div {
    justify-content: space-between;
  }
}

._subtitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 1.1875rem;
  line-height: 0.9375rem;
  color: $black;
}

._otpContainer {
  margin-top: 2.875rem;
  margin-bottom: 3rem;
}

@include responsive(md) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  ._form {
    margin-top: 0;
    margin-bottom: 0;
    height: inherit;
  }

  ._contentInput:first-child {
    margin-top: 0;
  }

  ._inputDualContainer {
    flex-direction: column;
  }

  ._button {
    width: 100%;

    &._button:last-child {
      margin-bottom: 0;
    }
  }
  ._bottomContainer {
    margin-bottom: 0;
  }

  ._input {
    margin-top: 0;
  }
  ._otpContainer{
   margin-top: 0;
    margin-bottom: 0;
  }

}

@include responsive(xl) {
  ._otp {
    > div > div {
      width: 14.5%;
      margin-right: 0 !important;
      > input {
        width: 100% !important;
      }
    }
  }
}
