@import '../../../../../public/styles/index.scss';

._toolbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.375rem;
  padding-right: 2.1875rem;
  width: 100%;
  @include responsive(lg) {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
}
