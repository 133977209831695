@import '../../../../../../public/styles/index.scss';

._rowChild {
  padding: 0.9375rem 0.625rem;
  box-sizing: border-box;
  border-bottom: 0.0313rem solid $gray;
}

._rowNoLine {
  @extend ._rowChild;
  border-bottom: none;
}

._list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: $space;
  font-size: 0.75rem;

  & li {
    line-height: 1.0625rem;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  & li:first-child {
    font-weight: bold;
  }
}

._amountsList {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: $space;
  font-size: 0.75rem;

  & li {
    line-height: 1.0625rem;
  }

  & li:first-child {
    font-weight: bold;
    color: $black !important;
    font-family: $space;
  }
}

._iconText {
  display: flex;
  & > div:nth-child(2) {
    width: 100%;
  }
}

._iconParent {
  margin-right: 0.25rem;
}

._date {
  font-family: $space;
	color: $placeholderGray;
	font-size: 0.75rem;
  padding: 1rem 1rem 0rem 1rem;
  box-sizing: border-box;
}

._textParent {
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center;

  & p {
    font-family: $space;
    font-size: 0.75rem;
  }
}

._listParent {
  font-family: $space;
  display: flex;
  justify-content: flex-end;

  & ul > li:first-child {
    color: $placeholderGray;
    text-align: right
  }
}


