@import '../../../public/styles/index.scss';

@mixin card() {
  background-color: $mantis;
  position: fixed;
  top: -15%;
  left: 50%;
  padding: 0.8125rem;
  width: 16.5rem;
  word-wrap: break-word;
  border-radius: 0.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1309;
  display: flex;
  transform: translate(-45%, -45%);
}

._iconParent {
  display: flex;
  align-items: center;
}

._leftSide {
  display: flex;
  align-items: center;

  & p {
    color: $white;
    font-family: $space;
    font-size: 0.875rem;
    margin-left: 10px;
    overflow-wrap: anywhere;
  }
}

._closeIconParent {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}

._pointer {
  cursor: pointer;
}

._static {
  @include card;
  top: -15%;
}

._card {
  @include card;
  animation: inAnimation .5s linear forwards
}

._hiddenCard {
  @include card;
  animation: outAnimation .5s linear forwards
}

@keyframes inAnimation {
  from { top: -15%; }
  to { top: 6% }
}

@keyframes outAnimation {
  from { top: 6% }
  to { top: -15% }
}
