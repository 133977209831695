@import '../../../../../../public/styles/index.scss';

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
}

._comeBackParent {
  display: flex;
  margin-top: 1.75rem;
  margin-bottom: 4.375rem;

  & p {
    font-family: $space;
    margin-left: 0.8125rem;
  }
}

._title,
._name {
  font-family: $space;
  font-weight: bold;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

._card {
  padding: 2.375rem 0.9375rem;
  background-color: $alabaster;
  border-radius: 9px;
}

._cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._deleteBtnParent {
  width: 7.1875rem;
  height: 2rem;
}

._editBtnParent {
  width: 8.75rem;
  height: 2rem;
}

._btnsParent {
  display: flex;

  & div:last-child {
    margin-left: 1.8125rem;
  }
}

._customBtn {
  justify-content: center;
  align-items: center;

  & p {
    margin-left: 0.50rem;
  }
}

._customBtnEdit {
  border: solid 1px $mandy;

  & div {
    margin: 0;
    padding: 0;
    display: flex;
  }

  & p {
    white-space: nowrap;
    margin-left: 0.50rem;
    text-align: center;
  }
}

._infoParent {
  display: flex;
  margin-top: 1.8125rem;
}

._column {
  width: 25%;
  p {
    word-break: break-all;
    max-width: 95%;
  }
}

._toBottom {
  margin-top: 5.1875rem;
}

._mobileName {
  display: none;
}

@mixin column($margin) {
  & p:first-child {
    font-size: 0.75rem;
    color: $gray;
    margin-bottom: 0.5rem;
  }

  & p:last-child {
    font-size: 0.75rem;
  }

  @if $margin {
    margin-top: $margin;
  }
}

._columTop {
  @include column(none);
}

._columBottom {
  @include column(2.375rem);
}

._line {
  display: none;
}

@include responsive(md) {
  ._infoParent {
    flex-direction: column;
    width: 100%;
    margin-top: 0.75rem;
  }

  ._comeBackParent {
    margin-bottom: 20px;
  }

  ._column {
    width: 100%;

    &:last-child div {
      border-bottom: solid 0.0625rem $alabaster;
    }
    p {
      max-width: 56%;
    }
  }
  ._toBottom {
    margin-top: auto;
  }
  ._columTop,
  ._columBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    padding: 18px 0px;
    border-bottom: solid 0.0625rem $gray;
    > p:first-child {
      white-space: nowrap;
    }
  }

  ._deleteBtnParent,
  ._editBtnParent {
    width: 50px;
  }

  ._editBtnParent {
    margin-left: 0.75rem !important;
  }

  ._customBtnEdit,
  ._customBtn {
    & div {
      margin-left: 2.5px !important;
      justify-content: center;
    }
  }

  ._name {
    display: none;
  }

  ._cardHeader {
    justify-content: flex-end;
  }

  ._mobileName {
    display: block;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 25px;
  }

  ._title{
    margin-top: 1rem;
  }
}
