@import "../../../../../../../public/styles/index.scss";

._container {
  display: flex;
  margin-top: 3.875rem;
  justify-content: center;
}

._qr {
  background-color: $white;
  border-radius: 0.5625rem;
}

._secret {
  text-align: center;
  margin-top: 2.375rem;
}

._suggestion {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 2rem;
}

@include responsive(md) {
  ._qr {
    background-color: $seashellsolid;
  }
}
