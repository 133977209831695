@import "../../../public/styles/index.scss";

._headerCellContainer {
  display: flex;
  align-items: center;
}

._tableMainContainer {
  padding-top: 1.8125rem;
  border-radius: 0.375rem;
  background-color: $alabaster;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  & ._titleContainer {
    font-size: 1rem;
    margin-bottom: 3.0625rem;
  }
}
._selected {
  color: $mandy;
}

._icon {
  cursor: pointer;
  height: 1.625rem;
  width: 1.625rem;
  -webkit-tap-highlight-color: transparent;
}

._childrenMargin {
  > *:not(:first-child) {
    margin-left: 1rem;
  }
}

._sortIcon {
  width: 1rem;
  height: 1rem;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

._paginationContainer {
  display: flex;
  width: 100%;
  padding-bottom: 1.5625rem;
  padding-top: 1.5625rem;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  ._pageText {
    font-size: 0.875rem;
  }
  ._arrowIcon {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }
  ._rightArrow {
    @extend ._arrowIcon;
    transform: rotate(180deg);
    margin-left: 1rem;
  }
}

._circularProgressContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

._emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7.75rem;
  > div {
    position: relative;
    background-color: white;
    width: 5.125rem;
    height: 5.125rem;
    border-radius: 50%;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 2.3125rem;
      height: 2.3125rem;
    }
  }
  > p {
    font-size: 0.75rem;
    font-weight: 700;
    margin-top: 3.25rem;
    text-align: center;
  }
}

._titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    display: none;
    width: 1.625rem;
    height: 1.625rem;
  }
}

@include responsive(lg) {
  ._header {
    display: none;
  }
  ._row {
    border-bottom: 0.0625rem solid $slateGray;
  }
  ._tableMainContainer {
    padding-top: 1.3125rem;
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
    & ._titleContainer {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }
  }
  ._titleContainer > svg {
    display: block;

  }
}
