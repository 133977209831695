@import "../../../../../public/styles/index.scss";

._mainContainer {
  margin-top: 0.5rem;
  width: 100%;
  @include scrollbar;
}

._circle {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._active {
  background-color: $mandy;
  p {
    color: $white;
  }
}

._inactive {
  border: 0.03125rem solid $pureBlack;
}

._label {
  color: $white;
}

._labelInactive {
  color: $slateGray;
}

._contentContainer {
  width: 41.0625rem;
  height: 29.25rem;
  margin: 5.625rem auto 0 auto;
  border-radius: 0.5625rem;
  background-color: $seashellsolid;
  padding: 3.9375rem 4rem 0 4rem;
  box-sizing: border-box;
}

._arrowBackContainer {
  display: flex;
  align-items: center;
  margin-left: 2.1875rem;
  margin-bottom: 1.4375rem;
  > p {
    color: $mandy;
  }
  img {
    margin-right: 1px;
    width: 1.5rem;
    height: 1.5rem;
  }
}

._footerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4.8125rem auto;
}

._mobileStepper {
  display: none;
}
._screenTitle {
  font-size: 1.25rem;
  text-align: center;
  font-weight: 700;
}
._buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 41.0625rem;
}

._cancelButton {
  animation: none;
  background-color: $silver;
  color: $black;
}

._buttonsCenter {
  justify-content: center;
}

._finalizeButton {
  width: 312px;
}

._mobileHide {
  display: none;
}

._modalBody {
  margin-top: 2.0625rem;
  text-align: center;
  font-size: 0.75rem;
}

@media (max-height: 916px) {
  ._contentContainer {
    height: unset;
    padding: 2.9375rem 4rem 2.9375rem 4rem;
    margin: 3.625rem auto 0 auto;
  }
  ._footerContainer {
    margin: 2.8125rem auto;
  }
}

@media (max-height: 730px) {
  ._contentContainer {
    height: unset;
    padding: 1.9375rem 4rem 1.9375rem 4rem;
    margin: 1.625rem auto 0 auto;
  }
  ._footerContainer {
    margin: 1.5rem auto;
  }
  ._mainContainer {
    margin-top: 1rem;
  }
}
@include responsive(lg) {
  ._leftSide {
    padding: 0.9375rem;
  }

  ._mainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  ._contentContainer {
    width: 100%;
  }
  ._buttonsContainer {
    width: 100%;
    margin-bottom: 0;
    padding: 2rem 0 3rem 0;
  }

  ._finalizeButton {
    width: 100%;
  }

  ._contentContainer {
    height: unset;
    background: none;
    padding: 0;
    //padding: 0 16px;
    margin-top: 2.375rem;
  }

  ._footerContainer {
    margin: auto 0 3.5rem 0;
    width: 100%;
  }
  ._mobileStepper {
    display: block;
  }
  ._arrowBackContainer {
    margin-left: 0;
  }
}

@include responsive(sm) {
  ._arrowBackContainer {
    margin-left: 0;
    margin-bottom: 0;
    > p {
      display: none;
    }
  }
  ._modalBody {
    br {
      display: none;
    }
  }
}
