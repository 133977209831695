@import "../../../../../../../public/styles/index.scss";

._title {
  font-family: $space;
  font-size: 1rem;
  text-align: center;
}

._iconParent {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 1.125rem;
}

._edit {
  font-weight: 700;
}

._newRoleText {
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

._showSaveButton {
  width: 10.0625rem;
  margin-top: 1rem;
  float: right;
}

._rolColor {
  font-weight: 700;
  font-size: 0.75rem;
  margin-top: 2.1875rem;
}

._colorsContainer {
  margin-top: 0.75rem;
  > div:not(:last-child) {
    margin-right: 0.735rem;
  }
}
._btnsParent {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.8125rem;
}

._btnParent {
  width: 12.125rem;
}

._radioContainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.125rem;
  line-height: 0.956875rem;
  > label {
    font-size: 0.75rem;
    margin-left: 0.625rem;
  }
}

._permissionsContainer {
  max-height: 15.625rem;
  overflow-y: auto;
  @include scrollbar;
  ._permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      font-size: 0.75rem;
    }
  }
  ._permission:not(:last-child) {
    margin-bottom: 1.375rem;
  }
}

._paginationContainer {
  display: flex;
  align-items: center;
  margin-top: 1.625rem;
  ._saveButton {
    width: 15.625rem;
    margin-left: 1.4375rem;
  }
}

._membersContainer {
  max-height: 15.625rem;
  overflow-y: auto;
  @include scrollbar;
  margin-top: 2.4375rem;

  ._member {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > svg {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
    }
    > div {
      display: flex;

      > p:first-child {
        font-weight: 700;
        margin-right: 1.5625rem;
        width: 9.375rem;
      }
      > p:nth-child(2) {
        width: 18.75rem;
      }
      > p {
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 0.75rem;
      }
    }
  }
  ._member:not(:last-child) {
    margin-bottom: 1.375rem;
  }
}

._circularProgressContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@include responsive(lg) {
  ._btnParent {
    width: 8.25rem;
  }

  ._iconParent {
    position: absolute;
    right: 0;
  }
  ._bottomContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 150px);
    > button {
      width: 100%;
      margin-top: auto;
    }
  }

  ._bottomContainer > ._paginationContainer {
    flex-direction: column;
    margin-top: auto;
    > button {
      width: 100%;
      margin-left: 0;
      margin-top: 1.25rem;
    }
  }
}
