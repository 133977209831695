@import "../../../../public/styles/index.scss";

._spacesParent {
  display: flex;
  position: absolute;
  right: 25rem;

  & div:not(:last-child) {
    margin-right: 10px;
  }
}

._spaceParent {
  $size: 2rem;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 25% * 2;
  width: $size;
  height: $size;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}

._spaceText {
  font-family: $space;
  font-size: 1rem;
  font-weight: 400;
}
