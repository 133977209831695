@import '../../../../public/styles/index.scss';

._main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);

}

._modal {
  @include card(60px);
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  // text-overflow: ellipsis;
  // padding-right: 4px;
}

._checkParent {
  $size: 9.25rem;
  background-color: $seashellsolid;
  width: $size;
  height: $size;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
}

._title {
  font-family: $space;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  word-wrap: break-word;
}

._titleInside {
  text-align: center;
  word-break: break-all
}

._toText {
  font-family: $space;
  font-size: 20px;
  text-align: center;

  & span {
    font-weight: bold;
  }
}

._memo {
  font-family: $space;
  font-size: 0.75rem;
  word-wrap: break-word;
  text-align: center;
}

._btnParent {
  margin-top: 1.3125rem;
  margin-bottom: 2.125rem;
}

._detailText {
  font-size: 0.75rem;
  color: $slateGray;
  text-decoration: underline;
  cursor: pointer;
}

@include responsive(md) {
  ._modal {
    width: 95%;
    @include card(3.125rem);
  }

  ._title, ._toText {
    font-size: 1.125rem;
  }

  ._memo {
    font-size: 0.625rem;
  }
}
