@import '../../../../../public/styles/index.scss';

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._scrollBar {
  & > *:first-child {
    @include scrollbar;
  }
}

._iconMargin {
  margin-right: 2.4375rem;
}

._toolbarContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 0.75rem;
  padding-top: 1rem ;
  padding-bottom: 0.5rem ;
}

._profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;

}

._profileText {
  font-size: 16px;
  margin-left: 0.4375rem;
}

._buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 0.8125rem;
  margin-bottom: 2.125rem;
  & > *:first-child {
    margin-right: 0.9375rem;
  }
}

._titleUp {
  margin-top: 2.8125rem;
  font-weight: 700;
}

._iconPosition {
  display: flex;
  align-items: center;
  & > div {
    position: initial !important;
  }
}

._profileTitle {
  cursor: pointer;
  margin-left: .75rem;
  &:hover {
    color: $mandy;
  }
}

._profileImage {
  $size: 2rem;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 25% * 2;
  width: $size;
  height: $size;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

}

._imageContainer{
  background: $seashellsolid;
  border-radius: 0.688rem;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  margin-top: 2.8125rem;
}

._imageCard{
  $size: 3.938rem;
  width: $size;
  height: $size;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0.4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}

._cardTextContainer{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
 align-items: flex-start;
 margin-left: 1.5rem;
}
._cardTitle{
  color: $black;
  font-size: 1rem;
  font-weight: 800;

}

._cardSubtitle{
  color: $black;
  font-size: 0.75rem;
}

._accountsMain{

  overflow-y: auto;
}
