@import '../../../public/styles/index.scss';
._popover {
  border-radius: 1.09375rem;
  //max-height: 50vh;

  @include scrollbar;
}

._optionSelected {
  background-color: rgba(230, 66, 122, 0.2) !important;
}
