@import "../../../../../../public/styles/index.scss";

._table {
  border-radius: 0 0 0.375rem 0.375rem;
  padding-top: 0rem;
}

._tableHeader {
  border-radius: 0.375rem 0.375rem 0 0;
  background-color: $alabaster;
}

._sortIconClass {
  margin-left: auto;
}
