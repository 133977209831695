@import "../../../../public/styles/index.scss";


._btnsParent {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.8125rem;
}

._btnParentLeft {
  width: 12.125rem;
}

._btnParentRight{
  width: 12.125rem;
}

._singleBtn{
  width: 12.125rem;
  margin: 0 auto;
}

._title {
  font-family: $space;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 800;
}

._successTitle{
  font-family: $space;
  font-size: 1rem;
  text-align: center;

  width: 60%;
  margin: 0 auto 2rem auto;
}



@include responsive(lg) {

  ._btnParentLeft {
    width: 8.25rem;
    margin-right: 1rem;
  }

  ._btnParentRight {
    width: 8.25rem;
    margin-left: 1rem;
  }
  ._textArea{
    height: 8rem;
  }
  ._successTitle{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  ._title{
    width: 60%;
    margin: 0 auto;
  }
}
