@import '../../../public/styles/index.scss';

._input {
  @include generalInput($black, $mandy);

  &:disabled {
    @include generalInput($silver, $silver);
    border: 1px solid $silver !important;
    &::placeholder {
      color: $silver;
    }
  }
}

._inputError {
  @include generalInput($valencia, $valencia);
  border: 1px solid $valencia !important;
}
._inputSuccess {
  @include generalInput($mantis, $mantis);
  border: 1px solid $mantis !important;
}

._textError {
  color: $valencia;
  font-size: 0.65rem;
  margin-top: 0.2rem;
}

._inputContainer {
  position: relative;
}

._iconNoPointer {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate(0, -50%);
  background-color: $white;
}

._iconPointer {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  transform: translate(0, -50%);
  background-color: $white;
}

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._inputWidth {
  width: 60%;
  @include responsive(sm) {
    width: 65%;
  }
}

._selectWidth {
  width: 36%;
  @include responsive(sm) {
    width: 30%;
  }
}
