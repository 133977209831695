@import '../../../../../public/styles/index.scss';

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._scrollBar {
  & > *:nth-child(2) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

._x {
  margin-left: auto;
}
._copyright {
  font-size: 0.875rem;
  padding: 0.8125rem 0.875rem 0.8125rem 0.875rem;
}
