@import '../../../../public/styles/index.scss';

._tableParent {
	background-color: $alabaster;
	padding: 1.2rem 0;
	box-sizing: border-box;
	border-radius: 0.5rem;
  overflow-y: scroll;
  @include scrollbar;
  position: relative;

	& * {
		font-family: $space !important;
		font-size: 0.75rem !important;
	}
}

._tableArea {
  table-layout: fixed;
}

._row {
	cursor: pointer;
  width: 100%;
}

._bold {
  font-weight: bold;
}

._list {
	list-style: none;
	padding: 0;
	margin: 0;
}

._headerParent {
	display: flex;
	justify-content: space-between;
	align-items: center;
  margin: 0 1.2rem 1.2rem 1.2rem;
}

._date {
	color: $placeholderGray;
	font-size: 0.75rem;
}

._incomesParent {
	display: flex;
	align-items: center;
	cursor: pointer;
  position: relative;

	& p {
		font-size: 0.75rem;
	}
}

@mixin option {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;

  & p:hover {
    color: $mandy;
    transition: all .6s;
  }
}

._textParent {
  margin-top: 3.125rem;
  & p:first-child {
    font-size: 0.75rem;
    text-align: center;
  }

  & p:last-child {
    font-weight: bold;
    text-align: center;
  }
}

._iconParent {
  $size: 5rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

._noTransactionsParent {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

._walletsParent {
  display: flex;
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
  @include scrollbar;
}

._walletButton {
  padding: 0.3125rem 1.25rem;
  cursor: pointer;

  & p {
    font-family: $space;
    font-size: 0.9375rem !important;
  }

  &:not(:last-child) {
    margin-right: 1.6875rem;
  }
}

._walletButtonSelected {
  @extend ._walletButton;
  background-color: $seashellsolid;
  border-radius: 1rem;
  transition: all .6s;
}

@mixin dropdown {
  position: absolute;
  right: 0;
  top: 1.875rem;
  width: 7.5rem;
  transition: all .6s;
  background-color: $mercury;
  border-radius: 0.5rem;
}

._option {
  @include option;
  height: 33.3%;
}

._optionHidden {
  @include option;
  height: 0px;
  overflow: hidden;
}

._dropdown {
  @include dropdown;
  height: 5.625rem;
}

._dropdownHidden {
  @include dropdown;
  height: 0rem;
  overflow: hidden;
}

._amount {
	font-weight: bold;
}

._totalAmount {
	color: $placeholderGray;
}

._typeWithWallet {
  display: flex;

  & p:first-child {
    font-weight: bold;
    margin-right: 0.5625rem;
  }
}

._boxParent {
	background-color: $wildSand;
	margin: 0;
	padding: 2.25rem 1.0625rem;
}

._boxTitle {
  font-family: $space;
  font-size: 1rem !important;
}

._grayRow {
	padding: 1.125rem 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:not(:last-child) {
		border-bottom: solid 0.0625rem $placeholderGray;
	}
}

._listArrow {
	display: flex;
	align-items: center;
  justify-content: center;
}

._circle {
  $size: 0.25rem;
  width: $size;
  height: $size;
  background-color: $mantis;
  border-radius: 50%;
  margin-right: 0.3125rem;
}

._redCircle {
  @extend ._circle;
  background-color: $sunglow;
}

._statusParent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

._arrowParent {
	margin-left: 1.25rem;
}

._closeParent {
	cursor: pointer;
}

._loaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

._noTransactionsText {
  text-align: center;
  font-size: 0.875rem;
}

._overLap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

._paginationParent {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.25rem;
}

._paginationContainer {
  display: flex;
  padding: 0 1.2rem;
}

._leftArrow {
  margin-right: 1.4375rem;
  cursor: pointer;
}

._rightArrow {
  margin-left: 23px;
  cursor: pointer;
}

._paginationText {
  font-family: $space;
  font-size: 12px;
}

._superParent {
  position: relative;
}
