@import '../../../../public/styles/index.scss';

._main {
  max-width: 45.625rem;
  max-height: 70vh;
  overflow-y: auto;
  @include scrollbar;
}

._row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

._leftSide {
  width: 30%;
}

._rightSide {
  width: calc(70% - 0.9375rem);
}

._buttonParent {
  width: 10.0625rem;
  display: block;
  margin-left: auto;
  margin-top: 6rem;
}

._buttonParentEdit {
  width: 10.0625rem;
  display: block;
  margin-left: auto;
  margin-top: 3rem;
}

._subtitle {
  font-family: $space;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}

._subtitleTwo {
  font-family: $space;
  margin-bottom: 1.875rem;
  font-size: 0.75rem;
}

._label {
  font-family: $space;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
}

._customInput {
  & input:disabled {
    color: $gray;
  }
}


._inputParent {
  position: relative;
  & input {
    margin-top: 0rem;
  }
}

._noMargin {
  margin-top: 0rem;
}

._searchIcon {
  position: absolute;
  right: 10px;
  top: 30%;
  transform: translate(-50%, 0);
}

@include responsive(md) {
  ._leftSide {
    width: 30%;
  }

  ._rightSide {
    width: calc(70% - 0.5rem);
  }

  ._buttonParent {
    margin-top: 3rem;
    width: 100%;
  }

  ._main {
    max-height: 50vh;
    overflow-y: auto;
  }

  ._textRadioContent {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;
  }

  ._emailRadio {
    margin: 0;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ._chooseAll {
    margin-left: 0.5rem;
  }

  ._radioContainer {
    margin: 0.5rem 0
  }

  ._buttonParent {
    width: 100%;
    margin-top: 1.5rem;
  }

  ._buttonParentEdit {
    width: 100%;
    margin-top: 1.5rem;
  }

  ._membersContainer {
    padding: 0.5rem;
  }

}
