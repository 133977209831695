@import '../../../../../public/styles/index.scss';


._mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}

._input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 5rem;
}

._buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

._buttonLeft {
  margin-top: 6rem;
  margin: 6rem 1rem 0 0;
  width: 50%;
}

._buttonRight {
  margin-top: 6rem;
  margin: 6rem 0 0 1rem;
  width: 50%;
}

._button {
  margin-top: 6rem;
  margin: 6rem 0 1rem 1rem;
  width: 50%;
}

._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._inputContainer {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

._form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 100%;
}

._bottomContainer{
margin-bottom: 5rem;
}

._error {
  color: red;
  text-align: center;
}

._tinyTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 1.875rem;
  margin-bottom: 0.8125rem;
  line-height: 15px;
  color: $black;

  & span {
    margin-left: 0.2rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

._footer {
  width: 75%;
  margin: 0 auto;
  align-self: flex-end;
}

._buttonText {
  font-family: $space;
  font-size: 0.8125rem;
  margin-left: 1rem;
}

._googleButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border: 0.063rem solid $black;
  border-radius: 1.25rem;
  cursor: pointer;
  font-family: $space;
  font-weight: 400;
  color: $black;
  animation-name: enableAnimation;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;


}

@include responsive(lg) {
  ._mainContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 10rem);
  }

  ._form {
    margin: 4rem auto 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  ._contentInput:first-child {
    margin-top: 0;
  }

  ._inputDualContainer {
    flex-direction: column;
  }

  ._button {
    width: 100%;

    &._button:last-child {
      margin-bottom: 0;
    }
  }
  ._bottomContainer{
    margin-bottom: 0;
  }

  ._input{
    margin-top: 0;
  }
}
