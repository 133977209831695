._backArrow {
  margin-right: 1.75rem;
  width: 25px;
  &:hover {
    cursor: pointer;
  }
}

._icon {
  cursor: pointer;
}
