@import "../../../public/styles/index.scss";

._otp {
  width: 100%;
  justify-content: space-between;
}

._otpInput {
  width: 4.4006rem !important;
  height: 2.4375rem;
  background-color: transparent;
  border: 0.0313rem solid $black;
  border-radius: 1.25rem;
  font-family: $space;
  font-size: 0.75rem;
  color: $black;
}

._errorMessage {
  color: $valencia;
  font-size: 0.65rem;
  margin-top: 0.2rem;
}

._error {
  border: 0.063rem solid $valencia;
}

._otpContainer {
  display: flex;
  flex-direction: column;
}

@include responsive(lg) {
  ._otp {
    justify-content: center;

    & > div:not(:last-child) {
      margin-right: 1rem;
    }
  }
  ._otpContainer {
    align-items: center;
  }
}
@include responsive(sm) {
  ._otp {
    justify-content: space-between;
  }
  ._otpContainer {
    align-items: unset;
  }
  ._otpInput {
    width: 100% !important;
  }
}
