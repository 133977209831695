@import '../../../../../../public/styles/index.scss';

._mainContainer {
  width: 80%;
  height: 90%;
  margin: 3rem auto 0 auto;
  display: flex;
  align-items: flex-start;
}

._arrowContainer {
  display: block;
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._inputContainer {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

._icon{
  font-size: 1.5rem
}

._mt {
  margin-top: 1.2rem;
}

._mb {
  margin-bottom: 3rem;
}

._inputDualContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._contentInput {
  width: 45%;
}

._contentTripleInput{
  width: 30%
}

._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  margin: 0.5rem 0 0 0;
}

._tinyTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 1.875rem;
  margin-bottom: 0.8125rem;
  line-height: 15px;
  color: $black;
}

._advice {
  @extend ._tinyTitle;
  margin-top: 0.8125rem;
}

._selectedToggle {
  border: solid 0.031rem $mandy;
  border-radius: 1.25rem;
  min-width: 90%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background-color: transparent;
  font-family: $space;
  box-sizing: border-box;
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

._toggle {
  border-radius: 1.25rem;
  min-width: 90%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background-color: transparent;
  font-family: $space;
  box-sizing: border-box;
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: solid 0.031rem $gray;
}

._toggleContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(1rem, 3.25rem);
  grid-gap: 5%;
  width: 100%;
}

._categorie {
  font-family: $space;
  font-size: 0.8125rem;
  margin-left: 1rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1.25rem;
}
._selectedCategorie {
  font-family: $space;
  font-size: 0.8125rem;
  margin-left: 1rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1.25rem;
  color: #E6427A
}

._form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

._error{
  color: red;
  text-align: center;
}

._loaderBox{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

._tripleContainer{
  display: flex;
  justify-content: space-between;
  align-content: center;
}

@include responsive(lg) {
  ._toggleContainer {
    grid-template-columns: repeat(2, 1fr);
  }

}

@include responsive(md) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
  }

  ._contentInput:first-child {
    margin-top: 0;
  }

  ._inputDualContainer {
    flex-direction: column;
  }

  ._contentInput {
    width: 100%;
    margin-top: 1.2rem;
  }

  ._arrowContainer {
    margin-bottom: 1rem;
  }

  ._mb {
    margin-bottom: 1rem;
  }

  ._advice {
    margin-top: 1.2rem;
  }
  ._toggle{
    border: solid  0.063rem $gray;
    width: 100%;
  }

  ._selectedToggle{
    border: solid 0.063rem $mandy;
    width: 100%;
  }

}
