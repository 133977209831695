$mandy:             #E6427A;
$mercury:           #E5E5E5;
$white:             #FFFFFF;
$silver:            #C4C4C4;
$overlay:           rgba(0, 0, 0, 0.33);
$gray:              #919191;
$placeholderGray:   #909090;
$black:             #111111;
$alto:              #D9D9D9;
$emperor:           #555555;
$valencia:          #d94848;
$wildSand:          #F4F4F4;
$alabaster:         #F9F9F9;
$gray:              #919191;
$seashellsolid:     #F1F1F1;
$mantis:            #65C65D;
$valencia:          #D94848;
$ronchi:            #EBC749;
$silver:            #CDCDCD;
$sunglow:           #FFCD29;
$cranberry:         #E35485;
$amour:             #FAEAF2;
$pureBlack:         #000000;
$slateGray:         #6B818C;
$amour:             #FAEAF2;
$athensGray:        #F5F5F5;

//FONT
$space: "Space Grotesk", sans-serif;
$cinzel: "Cinzel", sans-serif;
$raleway: "Raleway', sans-serif";
