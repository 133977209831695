@import '../../../../public/styles/index.scss';

._mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 28rem;
  width: 37.5rem;
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._inputContainer {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

._icon {
  font-size: 1.5rem
}

._mt {
  margin-top: 1.2rem;
}

._mb {
  margin-bottom: 3rem;
}

._inputDualContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

._firstDualContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._contentInput {
  width: 48%;
  ._amountClass {
    width: unset;
    padding-left: 0.8rem;
  }
}



._firstDualInput {
  width: 48%;
}


._contentFile {
  width: 48%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


._form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

._error {
  color: red;
  text-align: center;
}

._button {
  width: 25%;
  align-self: flex-end;
  margin-top: 2rem;
}

@include responsive(lg) {
  ._mainContainer {
    width: 100%;
  }
}

@include responsive(md) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  ._contentInput:first-child {
    margin-top: 0;
  }

  ._firstDualInput {
    margin-top: 0;
  }

  ._inputDualContainer {
    flex-direction: column;
    margin-top: 1rem;
  }

  ._firstDualContainer {
    margin-top: 0;
    display: flex;
  }

  ._contentInput {
    width: 100%;
    margin-top: 1.2rem;
  }

  ._mb {
    margin-bottom: 1rem;
  }

  ._button {
    width: 100%;
    align-self: flex-end;
    margin-top: 2rem;
  }

  ._contentFile {
    width: 100%;
  }

  ._inputContainer {
    margin: 0;
  }

  ._form {
    @include scrollbar;
    height: 60vh;
    margin: 0;
    overflow-y: scroll;
  }
}
