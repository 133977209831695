@import "../../../../../public/styles/index.scss";

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._titleContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 3rem;
  padding: 0 1.375rem;
  @include responsive(lg) {
    display: none;
  }
}

._profileImage {
  $size: 2rem;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 25% * 2;
  width: $size;
  height: $size;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}

._title {
  font-size: 1.25rem;
}
._subtitle {
  font-size: 0.75rem;
  color: $pureBlack;
}
._iconMargin {
  margin-right: 2.4375rem;
  margin-left: auto;
  @include responsive(lg) {
    margin-right: 1.875rem;
  }
}

._iconRightMargin {
  margin-right: 1.875rem;
}

._perfilText {
  font-size: 16px;
  margin-left: 0.4375rem;
}
._profile{
  display: flex;
  align-items: center;
  cursor: pointer;
}
._profileContainer {
  display: flex;
  align-items: center;
  @include responsive(lg) {
    display: none;
  }
}

._toolbarContainer {
  display: flex;
  align-items: flex-start;
  padding-left: 1.375rem;
  padding-right: 2.1875rem;
  position: relative;
  @include responsive(lg) {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    width: 100%;
  }
}

._spaceParent {
  $size: 2rem;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 25% * 2;
  width: $size;
  height: $size;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}

._spaceText {
  font-family: $space;
  font-size: 1rem;
  font-weight: 400;
}

._mobileMenuIcon {
  display: none;
  @include responsive(lg) {
    display: block;
  }
}

._appbarImage {
  display: none;
  @include responsive(lg) {
    display: block;
  }
}

._x {
  margin-left: auto;
}

._arrowBackContainer {
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    color: $black;
  }
}

._iconPosition {
  display: flex;
  align-items: center;
  & > div {
    position: initial !important;
  }
}

._profileTitle {
  cursor: pointer;
  margin-left: .75rem;
  &:hover {
    color: $mandy;
  }
}

._iconPosition {
  // margin-right: 1rem;
  @include responsive(lg) {
      margin-right: 1rem;
  }
}

._iconsContainer {
  margin-left: auto;
  display: none;
  align-items: center;
  @include responsive(lg) {
    display: flex;
  }
}
