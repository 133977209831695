@import '../../../../../../../public/styles/index.scss';

$size: 1rem;

._main {
  background-color: $alabaster;
}

._header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0 1.375rem;
  width: 100%;
}

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._content {
  margin-top: 4rem;
}

._title {
  font-size: $size;
  margin-bottom: $size;
  font-weight: bold;
}

._cardTitle {
  font-size: $size;
  font-weight: bold;
}

._modalCard {
  background-color: $seashellsolid;
  padding: 1.25rem;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.4375rem;
  }
}

._iconParent {
  display: flex;
  align-items: center;
}

._modalTitle {
  margin: 1.25rem 0;
  font-size: 1rem;
}

._leftSide {
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    margin-left: 0.5rem;
    font-size: $space;
    font-size: 1rem;
  }
}

._rightSide {
  display: flex;
  align-items: center;

  & p {
    font-size: 0.75rem;
    margin-right: 0.5rem
  }
}
