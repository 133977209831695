@import '../../../../public/styles/index.scss';

._contain{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
._title {
  font-family: $space;
  font-size: 1rem;
  text-align: left;
  margin: 1.25rem 0;
}

._iconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._icon {
  cursor: pointer;
}
._titleContainer{
  width: 100%;
}

._btnsParent {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.8125rem;
}

._btnParent {
  width: 12.125rem;
}

._accountsMain{
  height: 75%;
  max-height: 75%;
  min-height: 75%;
  @include scrollbar;
  overflow-y: auto;
}

._buttonsContainers{
  display: flex;
  justify-content: space-between;
  align-items: center;

}

._buttonParentLeft {
  width: 10.0625rem;
  display: block;

  margin-top: 1rem;
}
._buttonParentRight {
  width: 10.0625rem;
  display: block;

  margin-top: 1rem;
}
._row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


._miniTitle{
  font-size: 0.625rem;
  font-weight: 700;
  color: $placeholderGray;
}
._miniSubTitle{
  font-size: 0.625rem;
  font-weight: 400;
  color: $placeholderGray;
}

._bankTitleContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

._accountsContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $seashellsolid;
  border-radius: 0.375rem;
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem;
  cursor: pointer;
}

._accountSubtitle{
  font-family: $space;
  font-size: 0.75rem;

}

._accountTitle {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;

}

._bankIcon{
  background-color: $alabaster;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}

._defaultIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $alabaster;
  margin-right: 0.625rem;

}

._btnCutom{
  background-color: $amour !important;
  border: solid 1px $mandy !important;
  color: $mandy;
  animation-name: enableAnimation;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  &:disabled {
    background-color: $silver !important;
    color: $white !important;
    border: solid 1px $silver !important;
  }
}



@include responsive(lg) {
  ._btnParent {
    width: 8.25rem;
  }

  ._contain{
    flex-direction: row-reverse
  }
  ._iconParent{
    width: 50%;
  }
  ._titleContainer{
    width: 50%;
  }
  ._title{
    margin:0.625rem 0 ;
  }

  ._accountTitle{
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ._accountSubtitle{
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ._accountsContainer{
    padding: 1rem 0;
  }

  ._buttonParentLeft {
    width: 8.0625rem;
    margin-right: 1rem;
  }
  ._buttonParentRight {
    width: 8.0625rem;
    margin-left: 1rem;
  }
}
