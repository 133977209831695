@import '../../../../public/styles/index.scss';

//PAGINATION

._paginationParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}

._pagesNumber {
  font-family: $space;
  font-size: 0.75rem;
  color: $black;
}

._iconsParent {
  display: flex;
  align-items: center;
  & div:first-child {
    margin-right: 0.75rem;
  }

  & div {
    cursor: pointer;
  }
}
