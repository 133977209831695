@import '../../../../../../../public/styles/index.scss';

._smallSquare {
  background-color: $alabaster;
  padding: 14px 10px;
  border-radius: 0.375rem;
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

._arrowIcon {
  transform: rotate(90deg);
  width: 1.5rem;
  height: 1.5rem;
  color: $white;
  margin-right: 0.75rem;
}

._button {
  width: 48%;
  color: $amour;
  display: flex;
  align-items: center;
  justify-content: center;
}

._buttonsParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.125rem 0;
}

._cardContainer {
  margin-top: 1.125rem;
}

._smallCardsParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

@mixin smallSquare {
  background-color: $alabaster;
  border-radius: 0.375rem;
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 3.9375rem;
}

._smallSquare {
 @include smallSquare;
}

._disabled {
  @include smallSquare;
  opacity: 0.5;
}

._smallCard {
  background-color: $alabaster;
  padding: 0.875rem;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

._myAccounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.125rem;
  > p {
    font-size: 1rem;
    font-weight: 700;
  }
}
