@import '../../../../../../../public/styles/index.scss';

@keyframes disableAnimation {
  from {
    background-color: $amour;
  }

  to {
    background-color: $silver;
  }
}

@keyframes enableAnimation {
  from {
    background-color: $silver;
  }

  to {
    background-color: $amour;
  }
}

._btnsParent {
  display: flex;
  justify-content: flex-end;
}

._borderBottom {
  border-bottom: solid 0.5px $placeholderGray;
}

._btnCustom {
  background-color: $amour !important;
  border: solid 1px $mandy !important;
  color: $mandy;
  animation-name: enableAnimation;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;

  &:last-child {
    margin-left: 1.3125rem;
  }
}

._center {
  align-items: center;
}

._grayText {
  color: $placeholderGray;
}

._row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

._paddingTop {
  padding-top: 1.5rem;
}

._text {
  font-family: $space;
  font-size: 1rem;
}

._topMargin {
  margin-top: 4.375rem;
}

._iconStyles {
  margin-left: 0.9375rem;
}

._boldText {
  font-weight: bold;
}

._rightText {
  text-align: right;
}

._secondMarginTop {
  margin-top: 2.5rem;
}

._pointer {
  cursor: pointer;
}

._submitBtnParent {
  margin-top: 4.375rem;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

._customInput {
  display: flex;
  width: 100%;
}

._customInputReverse {
  width: 100%;
}

._responsiveButton {
  display: block;
}

._customInputReverse {
  display: flex;
  flex-direction: row-reverse;

  & input {
    text-align: right;
    unicode-bidi: bidi-override;
  }

  & p {
    margin-left: 0px;
  }

}

._extraPadding {
  padding-bottom: 1.6rem !important;
}

._underLine {
  text-decoration: underline;
}

._smallText {
  font-size: 0.875rem;
}

._inputRow {
  >div:nth-child(2) {
    width: 60%;
    max-width: 17.625rem;
  }
}

@include responsive(lg) {
  ._submitBtnParent {
    justify-content: center;
  }
}


@include responsive(md) {

  ._inputRow {
    >div:nth-child(2) {
      width: 70%;
    }
  }


  ._customInput {
    flex-direction: row-reverse;

    & input {
      text-align: right
    }

    & input::placeholder {
      text-align: right
    }

    &>p {
      display: none;
    }
  }

  ._topMargin {
    margin-top: 2.5rem;
  }

  ._row {
    padding-bottom: 0.8rem;
  }

  ._text {
    font-size: 0.75rem;
  }

  ._paddingTop {
    padding-top: 1.2rem;
  }

  ._secondMarginTop {
    margin-top: 0px;
  }

  ._submitBtnParent {
    justify-content: center;

  }

  ._main {
    @include card(1rem)
  }
}
