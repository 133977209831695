@import '../../../public/styles/index.scss';

@mixin line($background) {
  width: 2.4375rem;
  height: 0.1875rem;
  border-radius: 0.0938rem;
  background-color: $background;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

._step {
  @include line($alto);
}

._stepColor {
  @include line($mandy);
}

._parent {
  display: flex;
}
