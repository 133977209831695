@import '../../../../../../../public/styles/index.scss';

._icon {
  width: 1.5rem;
  height: 1.5rem;
}


._listContainer{
  height: 62vh;

  & > *:first-child {
    @include scrollbar;
  }
}

