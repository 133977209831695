@import '../../../../../../public/styles/index.scss';

._container {
  width: 70%;
  margin: 4rem auto 0 auto;
}

._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  margin: 0.5rem 0 2.875rem 0;
}

._btnParent {
  margin-top: 2.125rem;
}

._main {
  display: flex;
  flex-direction: column;
  height: 90%
}

@include responsive(md) {
  ._container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

  }
  ._main{
    height: calc(100vh - 10rem);
  }

}
