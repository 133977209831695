@import "../../../../../public/styles/index.scss";

._arrowBackContainer {
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  img {
    margin-right: 0.75rem;
  }
}

._tabsContainer {
  width: 70%;

  button {
    font-size: 1rem;
  }
}

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
  position: relative;
}

@include responsive(lg) {
  ._mainContainer {
    background-color: $athensGray;
    height: 100%;
    padding: 0 0.875rem 1rem 0.875rem;
  }
}

._bottomContainer {
  background-color: $alabaster;
  border-radius: 0.375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 1.3125rem;

  ._branchOfficeTitle {
    font-weight: 700;
  }

  ._subtitle {
    color: $placeholderGray;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    white-space: nowrap;
  }

  ._content {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-top: 0.9375rem;
  }

  ._gridContainerBorder {
    border-bottom: 0.0625rem solid $slateGray;
  }

  ._titleMargin {
    margin-top: 1.75rem;
  }

  ._item {
    margin-top: 2.5rem;
  }

  ._commerceType {
    display: flex;
    align-items: center;
    margin-top: 0.9375rem;

    >p {
      margin-top: 0;
    }

    >svg {
      margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}


._topContainer {
  margin-bottom: 1.4375rem;

  p {
    font-size: 1rem;
    font-weight: 700;
  }
}

._inputParent {
  width: 14.75rem;

  margin-top: 1.5rem;

  & input {
    margin-top: 0rem;
  }
}

._mediumContent {
  display: flex;
  justify-content: space-between;
}

._inputIcon {
  background-color: transparent;
}

._btnParent {
  display: flex;
  justify-content: flex-end;

  width: 30%;
  width: 12.5rem;
}

._headerParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._btnResponsive {
  width: 72px;
  height: 34px;

}

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._btnResponsiveContainer {
  width: 100%;
  display: none;
}

._noMargin {
  margin-top: 0rem;
}

._responsiveArrowBackContainer{
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

@include responsive(lg) {
  ._bottomContainer ._item {
    margin-top: 1.6875rem;
  }

  ._inputParent {
    width: 100%;
    margin-top: 0.875rem;
  }

  ._tabsContainer {
    width: 100%;
  }

  ._arrowBackContainer {
    margin-top: 1rem;
    display: none;
  }

  ._btnParent {
    display: none;
  }

  ._btnResponsiveContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 10px;
  }

}
