@import '../../../public/styles/index.scss';

._input {
  @include generalInput($black, $mandy);
  height: 6rem !important;
  resize: none;
  padding: 1rem;
  margin-top: 1rem;
}

._inputError {
  @include generalInput($valencia, $valencia);
  border: 0.063rem solid $valencia !important;
}
._inputContainer {
  position: relative;
}


._textError {
  color: $valencia;
  font-size: 0.65rem;
  margin-top: 0.2rem;
}
._maxValue{
  position: absolute;
  right: 0px;
  top: 10px;
}

@include responsive(md) {
  ._input{
    border: solid  0.063rem $gray;

  }
}
