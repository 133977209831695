@import '../../../public/styles/index.scss';

@mixin _btn {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 13px;
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  & p {
    font-size: 12px;
    font-weight: 400;
    font-family: $space;
  }
}

._contentParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

._baseBtn {
  @include _btn();
  justify-content: space-between;
}

._textContent{
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  width:50%
}

._loader {
  @include _btn();
  justify-content: center;
  cursor: inherit;
}

