@import "../../../../../../../public/styles/index.scss";

._mainContainer {
  padding-top: 1.375rem;
  padding-bottom: 2rem;
  ._row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      max-width: 4.0625rem;
    }
    ._receive {
      margin-right: 0.25rem;
    }
  }
  > ._row:nth-child(2) {
    padding-bottom: 2.25rem;
    border-bottom: 0.0313rem solid $black;
  }
  ._account {
    font-weight: 700;
  }
  ._print {
    text-decoration: underline;
    color: $slateGray;
    font-weight: 700;
  }
  ._accountNumberContainer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    cursor: pointer;
    > p {
      margin-left: 0.25rem;
    }
    ._accountNumber {
      text-decoration: underline;
      color: $slateGray;
      font-weight: 700;
    }
  }

  ._bottomContainer {
    padding-top: 3.5625rem;

    > a {
      display: block;
      text-align: center;
      margin-bottom: 1.5rem;
      color: $slateGray;
      text-decoration: underline;
      cursor: pointer;
    }

    > div {
      margin: 0 auto;
      padding: 0.625rem;
      border: 0.125rem solid $mandy;
      border-radius: 0.375rem;
      position: relative;
      width: 41%;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        background: #f9f9f9;
      }

      &::before {
        top: -0.3em;
        bottom: -0.3em;
        left: 1em;
        right: 1em;
      }

      &::after {
        left: -0.3em;
        right: -0.3em;
        top: 1em;
        bottom: 1em;
      }
      > img {
        position: relative;
        z-index: 1000;
        width: 100%;
      }
    }
  }
}

._codeParent {
  margin: 1.125rem 0 1.875rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

._codeContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

._codeTitle{
  text-align: center;
  font-family: $space;
  font-size: 1rem;
  margin-top: 2rem;
  width: 100%;
}

._codeSquare {
  $size: 1.875rem;
  width: $size;
  height: $size;
  border: solid 0.0625rem $mandy;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem;
  &:not(:last-child) {
    margin-right: 1.25rem;
  }

  & p {
    font-size: 2.5rem;
    color: $mandy;
    font-family: $space;
  }
}

._iconParent {
  $size: 5rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  border-radius: 50%;
}

._emptyState{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
._emptyContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

}

._header {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  cursor: pointer;
  & img {
    margin-right: 0.625rem;
  }
}

._title {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;
  line-height: 115%;
}

._emptyTitle{
  text-align: center;
}



@include responsive(lg) {
  ._mainContainer {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    ._bottomContainer {
      padding-top: 4.875rem;

      > a {
        text-align: center;
        margin-bottom: 1.5rem;
        color: $slateGray;
        text-decoration: underline;
        cursor: pointer;
      }

      > div {
        width: 60%;
      }
    }
  }
  ._codeSquare{
    $size: 0.1rem;
    width: $size;
    height: $size;
    padding: 1rem;

  & p {
    font-size:1.25rem;
    color: $mandy;
    font-family: $space;
  }

  }
  ._codeTitle{
    width: 80%;
  }
}
