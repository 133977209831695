@import '../../../public/styles/index.scss';

._contain{
  display: flex;
  flex-direction: column;
  width: 100%;
}
._title {
  font-family: $space;
  font-size: 1rem;
  text-align: left;
  margin: 1.25rem 0;
}

._iconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._icon {
  cursor: pointer;
}
._titleContainer{
  width: 100%;
}

._btnsParent {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.8125rem;
}

._btnParent {
  width: 12.125rem;
}

@include responsive(lg) {
  ._btnParent {
    width: 8.25rem;
  }

  ._contain{
    flex-direction: row-reverse
  }
  ._iconParent{
    width: 40%;
  }
  ._titleContainer{
    width: 60%;
  }
  ._title{
    margin:0.625rem 0 ;
  }
}
