@import '../../../../../public/styles/index.scss';

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._scrollBar {
  & > *:first-child {
    @include scrollbar;
  }
}

._icon {
  width: 1.5rem;
  height: 1.5rem;
}

._iconMargin {
  margin-right: 2.4375rem;
}

._toolbarContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 0.75rem;
  padding-top: 1rem ;
  padding-bottom: 0.5rem ;
}

._profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer
}
._perfilText {
  font-size: 16px;
  margin-left: 0.4375rem;
}

._directoryParentEmpty {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}

._directoryParent {
  overflow-y: auto;
}

//SUCURSAL STYLES
._sucursalCardParent {
  display: flex;
  background-color: $amour;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.375rem;
  justify-content: space-between;
  margin-bottom: 1.3125rem;
};

._rightSide {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & p {
    font-size: 0.75rem;
    font-family: $space;
  }
}

._textParent {
  margin-left: 0.75rem;
}

._leftSide {
  display: flex;

  & p:first-child {
    font-size: 0.75rem;
    font-weight: bold;
    font-family: $space;
    color: $mandy;
  }

  & p:last-child {
    font-size: 0.75rem;
    font-weight: bold;
    font-family: $space;
    color: $mandy;
  }
}

._buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 0.8125rem;
  margin-bottom: 2.125rem;
  & > *:first-child {
    margin-right: 0.9375rem;
  }
}

._iconInput {
  position: absolute;
  left: 90%;
  top: 25%;
  transform: translate(-50%, 0);
}

._title {
  font-weight: 700;
  transition: all .4s;
  font-size: 0.875rem;
  transform: scale(1);
}

._titleHidden {
  display: none;
}

._inputParent {
  position: relative;
  width: 55%;
  transition: all .4s;

  & input {
    margin-top: 0rem;
  }
}

._input {
  margin-top: 0rem;
  @include generalInput($black, $mandy);
  width: 100%;
  transition: all .4s;
}

._button {
  width: 9.0625rem;
  color: $amour;
  display: flex;
  align-items: center;
  justify-content: center;
}

._titleUp {
  margin-top: 2.8125rem;
  margin-bottom: 0.75rem;
  margin-left: 0.9375rem;
  font-weight: 700;
}
._seeMore {
  font-size: 0.75rem;
  cursor: pointer;
}

._listHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .4s;

  &:hover ._title {
    font-size: 0px;
    transition: all .4s;
  }

  &:hover ._inputParent {
    width: 95%;
    transition: all .4s;
  }

  &:hover > ._inputParent > ._input {
    width: 95%;
    transition: all .4s;
  }
}

._arrowIcon {
  transform: rotate(90deg);
  width: 1.5rem;
  height: 1.5rem;
  color: $white;
  margin-right: 0.75rem;
}

._counterPartsContainer {
  margin-top: 1.125rem;
  padding-right: 0.3125rem;
  overflow-y: auto;
  @include scrollbar;
}

._client {
  background-color: $seashellsolid;
  border-radius: 0.375rem;
}

._orderIcon {
  cursor: pointer;
  margin-right: 10px;
}

._iconPosition {
  display: flex;
  align-items: center;
  & > div {
    position: initial !important;
  }
}

._profileTitle {
  cursor: pointer;
  margin-left: .75rem;
  &:hover {
    color: $mandy;
  }
}

._profileImage {
  $size: 2rem;
  background-color: $alto;
  box-sizing: border-box;
  border-radius: 25% * 2;
  width: $size;
  height: $size;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
}
