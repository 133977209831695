@import '../../../public/styles/index.scss';

._selectContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

._select {
  width: 22.04%;
}

._selectValue {
  width: 57.55%;
}

._fileContainer {
  width: 12.44%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

._fileSelected {
  border: solid 0.063rem $gray;
  margin-top: 1.25rem;
  border-radius: 1.25rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background-color: transparent;
  font-family: $space;
  box-sizing: border-box;
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

._btn {
  width: 100%;
  height: 2.5rem;
  background-color: $mandy;
  border: none;
  color: $white;
  border-radius: 1.25rem;
  cursor: pointer;
  font-family: $space;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

._iconContainer {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

._fileName {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1.25rem;
}

._iconContainer {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

._error {
  font-family: $space;
  font-size: 0.75rem;
  margin-top: 0.3rem;
  text-align: center;
  color: red;
}

@include responsive(md) {
  ._selectValue {
    width: 50.55%;
  }
  ._fileContainer {
    width: 19.44%;
  }
}
