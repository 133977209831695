@import "../../../../../../../public/styles/index.scss";


._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
  position: relative;
}

._bankIcon{
  background-color: $seashellsolid;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}

._defaultIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $seashellsolid;
  margin-right: 0.625rem;

}
._addIcon{
  margin-right: 0.625rem;
}


  ._titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 1rem 0
  }

  ._title {
    font-size: 1rem;
    color: $black;
  }

  ._pinkTitle {
    font-size: 1rem;
    color: $mandy;
    text-decoration: underline;
    cursor: pointer;
  }


  ._subtitle {
    color: $placeholderGray;
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }

  ._emptySubtitle{
    height: 0.9375rem;
  }
  ._index{
    margin-left: 1rem;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-top: 0.9375rem;
    overflow-wrap: anywhere;
    overflow: hidden;
  }
._shareholdersBox{
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  background: $seashellsolid;
  flex-direction: column;

}
  ._shareholdersContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;


  }

  ._content {
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-top: 0.275rem;
    overflow-wrap: anywhere;
    overflow: hidden;

  }
  ._contentShareHolders{
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-top: 0.275rem;
    overflow-wrap: anywhere;
    overflow: hidden;
    font-weight: 700
  }
  ._contentSubtitle {
    font-size: 0.875rem;
    line-height: 0.9375rem;

    overflow-wrap: anywhere;
    overflow: hidden;
    font-weight: 400;
  }

  ._gridContainerBorder {
    border-bottom: 0.0625rem solid $slateGray;
  }



  ._item {
    margin-top: 1.5rem;
  }

  ._commerceType {
    display: flex;
    align-items: center;
    margin-top: 0.9375rem;

    >p {
      margin-top: 0;
    }

    >svg {
      margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }


._bottomContainer_child {
  background-color: $alabaster;
  border-radius: 0.375rem;
  padding: 1.3125rem  0.9375rem 2rem  0.9375rem ;
  & > *:first-child {
    @include scrollbar;
  }

}

._topContainer {
  margin-bottom: 1.4375rem;

  p {
    font-size: 1rem;
    // font-weight: 700;
  }
}
._accountSubtitle{
  font-family: $space;
  font-size: 0.75rem;

}
._titleBold{
  font-size: 1rem;
  font-weight: 700;
  margin: 1.5rem 0 0.5rem 0
}

._accordionTitle{
  font-size: 1rem;
  font-weight: 700;

}
._signatureContainer{
  width:100%;
  max-width: 100%;
  min-width: 100%;
  height: 5rem;
  max-height: 5rem;
  border-radius: 0.5rem;
  border: .5px solid $gray;
  margin-bottom: 1.5rem;
}

._signature{
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
._backgroundAccordion {
  background-color: $wildSand;
 padding-left: 16px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

._bussinesItem{
  margin-top: 1.5rem;
}

._shareHolderItems{
  margin: .5rem
}

._accountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $alabaster;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

._row{
  display: flex;
}


._miniTitle{
  font-size: 0.625rem;
  font-weight: 700;
  color: $placeholderGray;
}
._miniSubTitle{
  font-size: 0.625rem;
  font-weight: 400;
  color: $placeholderGray;
}
._accountTitle {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;

}

._loaderContainer{
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

._manageTitle{
  font-size: 1rem;
  color: $mandy;
  cursor: pointer
}

._btnsParent {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2.8125rem;
}

._btnParent {
  width: 12.125rem;
}



@include responsive(lg) {
  ._mainContainer {
    background-color: $athensGray;
    height: 100%;
    padding: 0 0.875rem 1rem 0.875rem;
  }
  ._btnParent {
    width: 8.25rem;
  }
}
