@import '../../../../../../public/styles/index.scss';

._mainContainer {
  width: 80%;
  height: 90%;
  margin: 3rem auto 0 auto;
  display: flex;
  align-items: flex-start;
}
._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  line-height: 0.956875rem;
  margin: 0.5rem 0 0 0;
}

._sectionTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 2.1875rem;
  margin-bottom: 1.8125rem;
  line-height: 0.956875rem;
}

._link {
  font-family: $space;
  font-size: 0.75rem;
  color: $mandy;
  line-height: 0.956875rem;
  margin-bottom: 1.125rem;
}
._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._bottomMargin {
  margin-bottom: 1.5rem;
}

._checkboxMargin {
  margin-top: 0.8125rem;
  margin-bottom: 0.8125rem;
}

@include responsive(lg) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
  }
  ._arrowContainer {
    margin-bottom: 1rem;
  }
  ._subtitle {
    padding-right: 0;
  }
}
