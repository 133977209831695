@import "../../../../../public/styles/index.scss";

@mixin optionContainer {
  background-color: $alabaster;
  border-radius: 0.313rem;
  min-height: 4.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.9375rem;

  &:hover {
    cursor: pointer;
    border: solid 0.063rem $mandy;
  }
}

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
  position: relative;
}

._paymentContainer {
  display: flex;
  justify-content: space-between;
}

._topContainer {
  margin-bottom: 1.4375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: 700;
    font-family: $space;
  }
}

._titleContainer {
  display: flex;
}

._leftContent {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 48%;
}

._rightContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 50%;
  padding-top: 3rem;
}

._tabsContainer {
  width: 100%;

  button {
    font-size: 1rem;
  }
}

._bottomLeftContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

._bottomRightContainer {
  // height: 100%;
  background-color: $alabaster;
  border-radius: 0.313rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.3125rem;
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  @include scrollbar;
}

._bottomRightAccounts{
  // height: 100%;
  background-color: $mercury;

  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  @include scrollbar;
}

._groupContainer{
  background-color: $alabaster;
}

._balanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 2rem 0;
}

._balanceParent {
  display: flex;
  align-items: center;
}

._optionContainer {
  @include optionContainer;
}

._optionContainerSelected {
  border: solid 0.063rem $mandy;
  @include optionContainer;
}
._iconContainer {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

._optionsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._textContent {
  display: flex;
  flex-direction: column;
}

._balanceTitle {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: $space;
  color: $black;
}

._title {
  font-size: 1rem;
  font-weight: 400;
  font-family: $space;
  color: $black;
  line-height: 1.2763rem;
}

._subtitle {
  font-size: 1rem;
  font-weight: 400;
  font-family: $space;
  color: $silver;
  line-height: 0.9569rem;
}


@include responsive(lg) {
  ._mainContainer {
    height: auto;
    padding: 0 0.875rem 1rem 0.875rem;
  }

  ._paymentContainer {
   // flex-direction: column;
    width: 100%;
 //   height: fit-content;
  }

  ._leftContent {
    width: 100%;
  }

  ._tabsContainer {
    max-width: 100%;
  }

  ._rightContent {
    width: 100%;
    padding-top: 0;
    height: 70vh;

  }

  ._optionContainerSelected {
    border: solid 0.063rem $mandy;
  }
  ._topContainer {
    margin-top: 1rem;
  }
}

._goToActivitiesParent {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

._goToActivitiesText {
  cursor: pointer;
  font-family: $space;
}

@include responsive(md) {
  ._bottomRightContainer {
    padding-left: 0px;
    padding-right: 0px;
  }

  ._optionContainer, ._optionContainerSelected{
    padding: 1.30rem;
    box-sizing: border-box;
  }
}
