@import '../../../../../../../public/styles/index.scss';

._row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.6rem;
}

._bold {
  font-weight: bold;
}

._paddingTop {
  padding-top: 1.5rem;
}

._text {
  font-family: $space;
  font-size: 1rem;
}

._topMargin {
  margin-top: 4.375rem;
}

._center {
  align-items: center;
}

._grayText {
  color: $placeholderGray;
}

._textLeft {
  font-family: $space;
  font-size: 1rem;
  text-align: right;
}

._borderBottom {
  border-bottom: solid 0.5px $placeholderGray;
}

._separation {
  margin-top: 2.25rem;
}

._boldText {
  font-weight: bold;
}

._separationTop {
 margin-top: 2.5rem;
}

._btnsContainer {
  display: flex;
}

._btnsContainer {
  margin-top: 4.375rem;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
}

._comeBack {
  background-color: $seashellsolid !important;
  color: $black;
}

._toStart {
  align-items: flex-start;
}

._main {
  display: block;
}

._maxWidth {
  width: 50%
}

@include responsive(md) {
  ._main {
    @include card(12px);
  }

  ._text, ._textLeft {
    font-size: 12px;
  }

  ._comeBack {
    display: none;
  }

  ._btnsContainer {
    justify-content: center;
  }
}

