@import "../../../../public/styles/index.scss";


._title {
  font-family: $space;
  font-size: 1rem;
  text-align: center;

}

._subtitle{
  font-family: $space;
  font-size: 0.75rem;
  text-align: center;
  width: 70%;
  margin: 1rem auto;

}

._button {
  width: 40%;
  margin: 1.5rem auto 0 auto
}

._2faTitle{
  font-family: $space;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 1rem;
}

._otp {

  > div {

    > div > input {
      width: 3.8125rem !important;
      margin-bottom: 2rem;
    }
  }
}

._line {
  width: 45%;
  height: 0.0625rem;
  background-color: $black;
}

._circle {
  $size: 0.38rem;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 0.063rem solid $black;
}

._separator {
  display: flex;
  justify-content: space-between;
  width: 60%;
  align-items: center;
  margin: 2.25rem auto 0 auto;
}

._registerText {
  display: flex;
  justify-content: center;
  margin: 1.375rem auto 0 auto;
  font-family: $space;
  color: $gray;
  font-size: 0.75rem;

  & span {
    margin-left: 0.3rem;
    text-decoration: underline;
    cursor: pointer;
    color: $mandy;
  }
}

@include responsive(lg) {

  ._button {
    width: 80%;
    align-self: flex-end;
    margin-top: 1rem;
  }
  ._otp {
    > div > div > input {
      width: 100% !important;
      border:0.063rem solid $gray;
      border-radius: 0.875rem;
    }
  }
  ._subtitle{
    width: 100%;
  }

  ._title{

    margin: 0 auto;
  }
  ._separator{
   width: 100%;
  }
  ._registerText{
    margin: 0.5rem auto;
  }
}
