@import "../../../../../../../public/styles/index.scss";

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
}

._arrowBackContainer {
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    color: $black;
  }
}

._titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > button {
    display: none;
    width: 72px;
    height: 34px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      > svg:first-child {
        width: 1.375rem;
        height: 1.375rem;
      }
      > svg:nth-child(2) {
        transform: rotate(45deg);
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
._btnParent {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

._headerParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._btnParent {
  width: 12.5rem;
}

._topContainer {
  margin-top: 2rem;
  margin-bottom: 1.4375rem;
  p {
    font-size: 1rem;
    font-weight: 700;
  }
}

._inputParent {
  width: 14.75rem;

  margin-top: 1.5rem;
  & input {
    margin-top: 0rem;
  }
}

._inputIcon {
  background-color: transparent;
}

._noMargin {
  margin-top: 0rem;
}

._icon {
  position: absolute;
  left: 90%;
  top: 30%;
  transform: translate(-50%, 0);
}

._ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 90%;
}

._mobileRow {
  line-height: 0.9569rem;
  font-size: 0.75rem;
}

@include responsive(lg) {
  ._mainContainer {
    background-color: $athensGray;
    height: 100%;
    padding: 0 0.875rem 1rem 0.875rem;
  }
  ._inputParent {
    width: 100%;
    margin-top: 0.875rem;
  }
  ._arrowBackContainer {
    display: none;
  }
  ._btnParent {
    display: none;
  }
  ._titleContainer > button {
    display: block;
  }
}
