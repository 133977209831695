@import "../../../../../public/styles/index.scss";

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
  position: relative;
}

@include responsive(lg) {
  ._mainContainer {
    background-color: $athensGray;
    height: 100%;
    padding: 0 0.875rem 1rem 0.875rem;
  }
}
