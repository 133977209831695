@import '../../../../public/styles/index.scss';

@mixin dropdown {
  position: absolute;
  right: 0;
  top: 1.875rem;
  width: 7.5rem;
  transition: all .6s;
  background-color: $mercury;
  border-radius: 0.5rem;
  z-index: 999;
}

@mixin option {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;

  & p:hover {
    color: $mandy;
    transition: all .6s;
  }
}

._card {
  background-color: $white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 0.7rem 0;
  position: relative;
  scroll-margin-top: 2rem;
}

._date {
  font-family: $space;
	color: $placeholderGray;
	font-size: 0.75rem;
  padding: 1rem 1rem 0rem 1rem;
  box-sizing: border-box;
}

._iconParent {
  $size: 5rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

._walletsParent {
  display: flex;
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
  overflow-x: auto;
  @include scrollbar;
}

._walletButton {
  padding: 0.3125rem 1.25rem;
  cursor: pointer;

  & p {
    font-family: $space;
    font-size: 0.875rem;
  }

  &:not(:last-child) {
    margin-right: 27px;
  }
}

._walletButtonSelected {
  @extend ._walletButton;
  background-color: $seashellsolid;
  border-radius: 1rem;
}

._tableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.9375rem;
  margin-bottom: 1.3125rem;

  & p:first-child {
    font-size: 1rem;
    font-family: $space;
    font-weight: bold;
  }

  & p:last-child {
    font-size: 0.75rem;
    font-family: $space;
  }
}

._textParent {
  margin-top: 3.125rem;

  & p:first-child {
    font-size: 0.75rem;
    text-align: center;
  }

  & p:last-child {
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
  }
}

._noTransactionsParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  height: inherit;
  height: 40vh;
}

._list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: $space;
  font-size: 0.75rem;

  & li {
    line-height: 1.0625rem;
  }

  & li:first-child {
    font-weight: bold;
  }
}

._noTransactionsText {
  text-align: center;
  font-size: 0.875rem;
  padding: 0.5rem 0;
  box-sizing: border-box;
}

._loaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

._iconText {
  display: flex;
}

._miniCardsParent {
  display: flex;
  justify-content: space-between;

  & div:last-child {
    margin-left: 1.0625rem;
  }
}

._iconMiniCardParent {
  display: flex;
  justify-content: center;
  align-items: center;
}

._miniCard {
  padding: 0.75rem;
  background-color: $alabaster;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.875rem;
}

._rowChild {
  padding: 0.9375rem 0.625rem;
  box-sizing: border-box;
  border-bottom: 0.0313rem solid $gray;
}

._simpleRowChild {
  padding: 1.25rem 0.625rem;
  box-sizing: border-box;
  border-bottom: 0.0313rem solid $gray;
}

._simpleRowNoLine {
  @extend ._simpleRowChild;
  border-bottom: none;
}

._rowNoLine {
  @extend ._rowChild;
  border-bottom: none;
}

._iconParent {
  margin-right: 0.25rem;
}

._arrowIconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

._listParent {
  font-family: $space;
  display: flex;
  justify-content: flex-end;

  & ul > li:first-child {
    color: $placeholderGray;
    text-align: right
  }
}

._smallCard {
  background-color: $white;
  padding: 0.875rem;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

._cardsParent {
  display: flex;
  margin-bottom: 0.875rem;

  & div:last-child {
    margin-left: 1.0625rem;
  }
}

._listHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

._inputParent {
  position: relative;
  flex: 1;

  & input {
    margin-top: 0rem;
  }
}

._orderIconParent {
  margin-left: 0.625rem;
}

._input {
  margin-top: 0rem;
  @include generalInput($black, $mandy);
  width: 100%;
  transition: all .4s;
}

._iconInput {
  position: absolute;
  left: 95%;
  top: 25%;
  transform: translate(-50%, 0);
}

._overLap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

._loaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

._paginationParent {
  display: flex;
  justify-content: center;
  margin-top: 3.25rem;
}

._paginationContainer {
  display: flex;
  padding: 0 1.2rem;
}

._leftArrow {
  margin-right: 1.4375rem;
  cursor: pointer;
}

._rightArrow {
  margin-left: 1.4375rem;
  cursor: pointer;
}

._paginationText {
  font-family: $space;
  font-size: 0.875rem;
}

._inputIcon {
  background-color: transparent;
  right: 5%;
}

._headerParent {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.125rem;

  & input {
    width: 100%;
  }
}

._orderIcon {
  margin-left: 1rem;
}

._noMargin {
  flex: 1;
}

._incomesParent {
	display: flex;
	align-items: center;
	cursor: pointer;
  position: relative;
  margin-left: 0.8rem;
  width: 30%;
  margin-right: auto;
  justify-content: flex-end;

	& p {
		font-size: 0.75rem;
	}
}

._dropdown {
  @include dropdown;
  height: 5.625rem;
}

._dropdownHidden {
  @include dropdown;
  height: 0rem;
  overflow: hidden;
}

._option {
  @include option;
  height: 33.3%;
}

._optionHidden {
  @include option;
  height: 0px;
  overflow: hidden;
}

