@import '../../../../public/styles/index.scss';

@mixin input($borderColor) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding: 0 1.25rem;
  border: solid 0.031rem $borderColor;
  border-radius: 1.25rem;
  width: 17.625rem;

  & input {
    background-color: transparent;
    border: none;
    font-family: $space;
    width: 100%;
  }
}

._textError {
  color: $valencia;
  font-size: 0.65rem;
  margin-top: 0.2rem;
}

._inputParent {
  @include input($black);
}

._inputError {
  @include input($valencia);
}

._inputSuccess {
  @include input($mantis);
}

._label {
  font-size: 0.875rem;
  font-family: $space;
  color: $placeholderGray;
  margin-left: 0.625rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@include responsive(md) {
  ._inputParent {
    border: solid 0.063rem $gray;
    & input {
      font-size: 0.75rem;
    }
  }
  ._inputError {
    border: solid 0.063rem $valencia;
  }
}
