@import '../../../../../../public/styles/index.scss';

._mainContainer {
  width: 80%;
  height: 90%;
  margin: 3rem auto 0 auto;
  display: flex;
  align-items: flex-start;
}
._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  line-height: 0.956875rem;
  margin: 0.5rem 0 0 0;
}

._sectionTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 1.875rem;
  //margin-bottom: 0.8125rem;
  line-height: 0.956875rem;
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._formStyles {
  display: flex;
  flex-direction: column;
  height: 100%;
}

._inputsMargin {
  margin-top: 1.4375rem;
  margin-bottom: 0.9375rem;
}

._inputsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.0625rem;
  & > div {
    width: 48%;
  }
}

._bottomMargin {
  margin-bottom: 2.125rem;
}

._checkboxContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.875rem;
  margin-bottom: 1.8125rem;
}
._checkboxMargin {
  margin-left: 2.5625rem;
}

@include responsive(lg) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
  }
  ._arrowContainer {
    margin-bottom: 1rem;
  }
  ._subtitle {
    padding-right: 0;
  }
  ._scrollableContainer {
    padding-right: 0;
  }
}

@include responsive(md) {
  ._inputsContainer {
    margin-bottom: 1.9375rem;
    & > div {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
  ._inputsMargin {
    margin-bottom: 0;
  }
}
