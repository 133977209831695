@import '../../../../../../public/styles/index.scss';

@mixin rowMargin {
  margin-top: 0.8125rem;
}

._mainContainer {
  width: 83%;
  height: 90%;
  margin: 3rem auto 0 auto;
  display: flex;
  align-items: flex-start;
}
._title {
  font-family: $space;
  font-size: 1rem;
  font-weight: 700;
}

._nameInput {
  width: 48%;
}

._subtitle {
  font-family: $space;
  font-size: 0.8125rem;
  color: $gray;
  margin: 0.5rem 0 0 0;
  line-height: 0.956875rem;
  padding-right: 2.125rem;
}

._error {
  font-family: $space;
  font-size: 0.75rem;
  margin-top: 0.3rem;
  text-align: center;
  color: red;
}

._sectionTitle {
  font-family: $space;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 1.875rem;
  line-height: 0.956875rem;
}

._content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

._headerRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

._firstRow {
  display: flex;
  justify-content: space-between;
}
._secondRow {
  @include rowMargin;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

._fourthRow {
  @include rowMargin;
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    width: 45%;
  }
  & > div:last-child {
    width: 51%;
  }
}

._thirdRow {
  @include rowMargin;
  margin-top: 0.8125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._paperclipImage {
  margin-top: 0.1875rem;
}

._inputNumber {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

._addButton {
  display: flex;
  align-items: center;
  margin-top: 1.9375rem;
  cursor: pointer;
}

._addButtonText {
  font-family: $space;
  font-size: 0.75rem;
  margin-left: 0.5625rem;
}

._removeShareholderContainer {
  display: flex;
  align-items: center;
}
._formContainer {
  margin-top: 0.8125rem;
}
._formSeparator {
  padding-bottom: 1rem;
  border-bottom: 0.063rem solid $gray;
}

._formStyles {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._scrollableContainer {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 2.125rem;
  margin-top: 1.3125rem;
  @include scrollbar;
}

._shareholderText {
  font-family: $space;
  font-weight: 700;
  font-size: 12px;
}

@include responsive(lg) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
  }
  ._arrowContainer {
    margin-bottom: 1rem;
  }
  ._subtitle {
    padding-right: 0;
  }
  ._scrollableContainer {
    padding-right: 0;
  }
}

@include responsive(sm) {
  ._fourthRow {
    & > div:first-child, div:last-child {
      width: 100%;
    }
    & > div:last-child {
      margin-top: 0.8125rem;
    }
    flex-direction: column;
  }
}
