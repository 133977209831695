@import '../../../public/styles/index.scss';

._button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  align-items: center;
  background: $mandy;
  height: 2.1875rem;
  padding: 0 0.75rem;
  // border-top-left-radius: 0.3125rem;
  // border-top-right-radius: 0.3125rem;
  border-radius:0.3125rem;
}

._buttonContent {
  display: flex;
  align-items: center
}

._text {
  margin-left: 0.8125rem;
  color: $white;
}

@mixin drop {
  background-color: $silver;
  position: absolute;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  z-index: 1300;
  transition: all .6s;
  overflow: hidden;
  height: 0px;
  width: 100%;
}

._dropStatic {
  @include drop;
  height: 4.625rem;
}

._dropHidden {
  @include drop;
  height: 0rem;
  overflow: hidden;
}

._arrowContainer {
  margin-right: 1rem;
}

@mixin dropTitle {
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  text-align: left;
  transition: all .6s;
  height: 0px;
  &:hover {
    background-color: $alabaster;
    transition: all .6s;
  }
}

@mixin _dropText{
  font-family: $space;
  font-weight: 400;
  font-size: 0.75rem;
  transition: all .6s;
}

._dropTextStatic{
  @include _dropText;
  height: 100%

}

._dropTextHidden{
  @include _dropText;
  height: 0px
}

._dropTitleStatic {
  @include dropTitle;
  height: 50%;
}

._dropTitleHidden {
  @include dropTitle;
  height: 0px;
  overflow: hidden;
}


@include responsive(md) {
  ._baseBtn{
    justify-content: space-between;
    align-items: center;
  }
}

