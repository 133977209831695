@import '../../../public/styles/index.scss';
._signaturesContainer {
  height: 97px;
  border: 0.031rem solid $gray;
  border-radius: 6px;
  padding-left: 0.8125rem;
  padding-right: 1.0625rem;
}

._signatureLine {
  width: 100%;
  position: absolute;
  user-select: none;
  touch-action: none;
  bottom: 15px;
  border-bottom: 0.031rem solid $placeholderGray;
}

._relativeWrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
._signaturePad {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

._clearSignatureIcon {
  cursor: pointer;
  position: absolute;
  background-color: white;
  bottom: 20px;
  right: 0;
}

._signatureOptions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.6875rem;
  color: $black;
  & > p:first-child,
  p:nth-child(2) {
    margin-right: 1.625rem;
  }
  & > p {
    font-family: $space;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    cursor: pointer;
  }
}

._selected {
  color: $mandy;
  &::after {
    display: block;
    content: "";
    border-bottom: solid 1.5px $mandy;
  }
}

._input {
  position: absolute;
  bottom: 1.5rem;
  font-family: $cinzel;
  font-size: 1.25rem;
  line-height: 0.16875rem;
  color: $black;
  font-weight: 400;
  width: 94%;
  border: none;
}

._errorMessage {
  font-family: $space;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: $valencia;
  margin-top: 0.4rem;
}

._attachInput {
  max-width: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
  &::-webkit-file-upload-button {
    cursor: pointer;
    width: 100%;
  }
}

._file {
  border: solid 0.063rem $gray;
  margin-top: 1.25rem;
  border-radius: 1.25rem;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.8rem;
  background-color: transparent;
  font-family: $space;
  box-sizing: border-box;
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

._fileSelected {
  border: solid 0.063rem $gray;
}

._fileNotSelected {
  border: solid 0.063rem $gray;
}

._fileName {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1.25rem;
}

._iconContainer {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}
