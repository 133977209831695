@import '../../../../public/styles/index.scss';

._mainContainer {
position: relative;
  min-height: 28rem;
  width: 37.5rem;
}

._content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

._contentMonth{
  padding: 0 1rem 0 1rem;
  background-color: $seashellsolid;
  height: 2.563rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

._text{
  font-size: 1rem;
  font-weight: 400;
  font-family: $space;
}

._advice{
  font-size: 0.75rem;
  font-weight: 400;
  font-family: $space;
  margin-top: 1rem;
}

._button {
  width: 25%;
  align-self: flex-start;
  margin-top: 1rem;
}
@include responsive(lg) {
  ._mainContainer{
    width: 100%;
  }
}

@include responsive(md) {
  ._mainContainer {
    width: 100%;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  ._contentFile {
    width: 100%;
  }

  ._button {
    width: 50%;
  }

  ._text{
    font-size: 0.8rem;
  }

  ._inputContainer {
    margin: 0;
  }

  ._form{
    @include scrollbar;
    height:60vh;
    margin: 0;
    overflow-y: scroll;
  }
}
