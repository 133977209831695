@import "../../../../public/styles/index.scss";

._simpleRowChild {
  padding: 1rem 0.625rem 1rem 0.625rem;
  box-sizing: border-box;
  border-bottom: 0.0313rem solid $gray;
}

._row {
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.3125rem;
  }
}

._simpleRowNoLine {
  @extend ._simpleRowChild;
  border-bottom: none;
}

._iconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: $space;
  font-size: 0.75rem;

  & li {
    line-height: 0.956875rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & li:first-child {
    font-weight: bold;
  }
}

._addBranchOffice {
  font-size: 0.75rem;
  font-weight: bold;
}

._iconText {
  display: flex;
}

._iconParent {
  margin-right: 0.25rem;
}

._arrowIconParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

._arrow {
  cursor: pointer;
}

._sucursalCardParent {
  display: flex;
  background-color: $amour;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.375rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  line-height: 0.956875rem;
  cursor: pointer;
}

._rightSide {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & p {
    font-size: 0.75rem;
    font-family: $space;
  }
}

._textParent {
  margin-left: 0.75rem;
}

._leftSide {
  display: flex;

  & p:first-child {
    font-size: 0.75rem;
    font-weight: bold;
    font-family: $space;
    color: $mandy;
  }

  & p:last-child {
    font-size: 0.75rem;
    font-weight: bold;
    font-family: $space;
    color: $mandy;
  }
}

._selected {
  border: solid 1px $mandy;
}
