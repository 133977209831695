@import '../../../../../../public/styles/index.scss';

._mainContainer {
  width: 100%;
  padding: 0 1.375rem 1rem 1.375rem;
  box-sizing: border-box;
  background-color: $mercury;
  display: flex;
  flex-direction: column;
}

._inputParent {
  width: 14.75rem;
}

._titleParent {
  display: flex;
  flex: 1;
  align-items: center;
}

._rowNoMargin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.6875rem;
}

._row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4375rem;
}

._title {
  font-family: $space;
  font-weight: bold;
  font-size: 1rem;
}

._exportBtnParent {
  width: 12.5rem;
  height: 2.5rem;
}

._tableHeader {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.25rem 0rem 1.25rem;
  border-radius: 0.375rem 0.375rem 0 0;
  background-color: $alabaster;
  align-items: center;
}

._btnParent {
  // width: 121px;
  width: 136px;
}

._walletsParent {
  display: flex;
}

._walletButton {
  padding: 0rem 1.25rem;
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    font-family: $space;
    font-size: 0.9375rem !important;
  }

  &:not(:last-child) {
    margin-right: 1.6875rem;
  }
}

._walletButtonSelected {
  @extend ._walletButton;
  background-color: $seashellsolid;
  border-radius: 1rem;
  transition: all .6s;
}

._addResponsiveBtn {
  width: 4.5rem;
}

._responsiveBtn {
  justify-content: center;
  & div {
    margin-left: auto;
  }
}

._desktopBtn {
  & div > div > p{
    width: 100%;
    text-align: left;
  }
}

._exportResponsiveBtn {
  width: 6.75rem;
  height: 2.5rem;
  margin-left: 1.3125rem;
}

._icon {
  background-color: transparent;
}

._responsiveRow {
  display: none;
}

@include responsive(lg) {
  ._walletsParent {
    justify-content: space-between;
    margin-bottom: 1.125rem;
    padding: 0;
    width: 100%;
  }

  ._walletButton,
  ._walletButtonSelected {
    margin-right: 0rem;
    padding: 0 0.4rem;

    & p {
      font-size: 0.75rem !important;
    }

    &:not(:last-child) {
      margin-right: 0rem;
    }
  }

  ._tableHeader {
    padding: 1rem 0.4rem;
  }

  ._btnParent {
    display: none;
  }

  ._exportBtnParent {
    display: none;
  }

  ._inputParent {
    width: 100%;
  }

  ._responsiveRow {
    display: flex;
    align-items: center;
  }

  ._rowNoMargin {
    margin-top: 2.1875rem;
  }
}
