@import '../../../../public/styles/index.scss';

._main {
  padding: 1.0625rem;
  box-sizing: border-box;
  background-color: $alabaster;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._balanceText {
  font-size: 1rem;
  font-weight: bold;
  font-family: $space;
}

._header {
  display: flex;
  justify-content: space-between;
}

._footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

._percentParent {
  display: flex;
  align-items: flex-end;
  height: 100%;

  & p {
    color: $silver;
    font-size: 0.8125rem;
  }
}

._amountParent {
  display: flex;
  align-items: flex-end;
}

._amountText {
  font-size: 2rem;
  font-family: $space;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  line-height: 85%;

}

._iconParent {
  display: flex;
  align-items: flex-end;
}
