@import '../../../public/styles/index.scss';

@keyframes disableAnimation {
  from {
    background-color: $mandy;
  }
  to {
    background-color: $silver;
  }
}

@keyframes enableAnimation {
  from {
    background-color: $silver;
  }
  to {
    background-color: $mandy;
  }
}

@keyframes enableAnimationGoBack {
  from {
    background-color: $silver;
  }
  to {
    background-color: $silver;
  }
}

._btn {
  width: 100%;
  height: 2.5rem;
  border: none;
  color: $white;
  border-radius: 1.25rem;
  cursor: pointer;
  font-family: $space;
  font-weight: 400;
  animation-name: enableAnimation;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0s;
  &:disabled {
    cursor: default;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-name: disableAnimation;
    animation-duration: 0s;
  }
}

._btnGoBack {
  width: 100%;
  height: 2.5rem;
  border: none;
  color: $black;
  border-radius: 1.25rem;
  cursor: pointer;
  font-family: $space;
  font-weight: 400;
  animation-name: enableAnimationGoBack;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  &:disabled {
    cursor: default;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-name: disableAnimation;
    animation-duration: 0.3s;
  }
}
