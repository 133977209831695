@import '../../../../public/styles/index.scss';

@mixin directory {
  border-radius: 0.625rem 0.625rem 0 0;
  display: flex;
  height: 100%;
}

._listHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

._inputParent {
  position: relative;
  flex: 1;

  & input {
    margin-top: 0rem;
  }
}

._orderIconParent {
  margin-left: 0.625rem;
}

._input {
  margin-top: 0rem;
  @include generalInput($black, $mandy);
  width: 100%;
  transition: all .4s;
}

._iconInput {
  position: absolute;
  left: 95%;
  top: 25%;
  transform: translate(-50%, 0);
}

._orderIconParent {
  margin-left: 0.625rem;
}

._textParent {
  & p {
    font-size: 0.75rem;
    font-family: $space;
    margin-top: 2rem;
  }
}

._iconParent {
  $size: 5rem;
  width: $size;
  height: $size;
  background-color: $seashellsolid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

._emptyStateParent {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  margin-top: 3.5rem;
}

._loaderParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

._directoryParent {
  @include directory;
  flex-direction: column;
  background-color: $alabaster;
}

._directoryEmpty {
  @include directory;
  border-radius: 0.625rem;
}

._paginationParent {
  margin-top: 1.125rem;
}

._parent {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  height: 100%;
}

._inputIcon {
  background-color: transparent;
  right: 5%;
}

._noMargin {
  width: 85%;
}

._formContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

._orderIcon {
  cursor: pointer;
  margin-right: 10px;
}
